import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from "react";
import "./Onboarding.css";
import "../../App.css";
import OnboardingScreen1 from "./OnboardingScreen1";
import OnboardingScreen2 from "./OnboardingScreen2";
import OnboardingScreen3 from "./OnboardingScreen3";
import OnboardingScreen4 from "./OnboardingScreen4";
import { getOnboardingData } from "../../Actions/Onboarding";
import { refreshToken } from "../../Actions/Auth";
import { onboardingDataContext, userObjectContext, usersData } from "../../App";
import OnboardingZipCodeScreen from "./OnboardingZipCodeScreen";
import OnboardingScreen5 from "./OnboardingScreen5";
import { useNavigate } from "react-router";
import OnboardingFinalScreenNew from "./OnboardingFinalScreenNew";
import ErrorModal from "../Modals/ErrorModal";

function Onboarding() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [isBusinessSelected, setIsBusinessSelected] = useState(false);
  const [selectBusinessTypeProp, setSelectBusinessTypeProp] = useState("");
  const { setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  // const {showPassZipRef } = useContext(onboardingDataContext);
  const [videoUrl, setVideoUrl] = useState({});
  const [zip, setZip] = useState("");
  const [userObject, setUserObject] = useState({});
  const [userData, setUserData] = useState({});
  const [userAuth, setUserAuth] = useState({});
  const [changeBusinessType, setChangeBusinessType] = useState(false);
  const [allowForceBackNav, setAllowForceBackNav] = useState(false)
  // const {userObject, setUserObject} = useContext(userObjectContext)

  const navigate = useNavigate();

  const handlePassInput = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPassInput = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleNext = () => {
    console.log("handleNextCalled", pageNumber);
    if (pageNumber === 8) return;

    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const handlePrev = () => {
    if (pageNumber === 1) return;
    setPageNumber((prevPageNumber) => prevPageNumber - 1);
  };

  const handleBusiness = (business) => {
    setSelectBusinessTypeProp(business);
    setIsBusinessSelected(true);
  };

  const getData = async () => {
    return getOnboardingData("getUserData", {})
      .then((res) => {
        // console.log(res)
        setUserObject(res.data.data);

        localStorage.setItem("userObject", JSON.stringify(res.data.data));
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          refreshToken().then((res) => {
            getData();
          });
        } else if (
          error?.response?.status == 502 ||
          error?.response?.status == 503 ||
          error?.response?.status == 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status == 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              getData();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              getData();
            },
          });
        }
      });
  };

  useEffect(() => {
    console.log("pageNumber", pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    //set userData
    let data = localStorage.getItem("userData");
    //if not logged in -> navigate to login page
    let parsedData=JSON.parse(data)
console.log(parsedData);
    if(data && ("businessType" in parsedData) && parsedData.zip !== null) {
      console.log(data);
      setPageNumber(3)
      localStorage.setItem("currentScreen", "onboarding_screen");
      localStorage.removeItem("currentScreenBannerData");
  
      data = JSON.parse(data);
      // console.log('from userdata',data?.businessType.length > 0 || false )
  
      if (!(data.businessType === null)) {
        
        if (data?.businessType[0] === "PHARMACY" && data.enabled) {
          navigate("/home");
        }
      }
  
      data?.businessType
        ? setIsBusinessSelected(data?.businessType.length > 0)
        : setIsBusinessSelected(false);
  
      //set authData
      let authData = localStorage.getItem("userAuth");
      authData = JSON.parse(authData);
  
      //get/set userObjects
      const fetchData = async () => {
        await getData();
        console.log("data fetched");
      };
    } else {
      console.log(parsedData);
      localStorage.setItem("screenCount", 0);
      localStorage.removeItem("currentScreen");
      localStorage.removeItem("currentScreenStartTime");
      localStorage.removeItem("userData");
      localStorage.removeItem("userAuth");
      localStorage.removeItem("userObject");
      localStorage.removeItem("onBehalfRetCode");
       navigate("/login");
    }
  }, []);

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      {pageNumber === 1 && (
        <OnboardingScreen1
          password={password}
          confirmPassword={confirmPassword}
          handlePassInput={handlePassInput}
          handleConfirmPassInput={handleConfirmPassInput}
          handleNext={handleNext}
          // showSetPasswordScreen={showPassZipRef.current.showSetPasswordScreen}
        />
      )}
      {pageNumber === 2 && (
        <OnboardingZipCodeScreen
          handleNext={handleNext}
          // showZipCodeScreen={showPassZipRef.current.showZipCodeScreen}
          changeBusinessType={changeBusinessType}
          setZip={setZip}
          zip={zip}
        />
      )}
      {pageNumber === 3 && (
        <OnboardingScreen2
          handlePrev={handlePrev}
          handleBusiness={handleBusiness}
          // isBusinessSelected={showPassZipRef.current.isBusinessSelected}
          handleNext={handleNext}
          setVideoUrl={setVideoUrl}
          changeBusinessType={changeBusinessType}
          setChangeBusinessType={setChangeBusinessType}
          setSelectBusinessTypeProp={setSelectBusinessTypeProp}
          selectBusinessTypeProp={selectBusinessTypeProp}
        />
      )}
      {pageNumber === 4 && (
        <OnboardingScreen3
          handleNext={handleNext}
          handlePrev={handlePrev}
          videoUrl={videoUrl}
          isBusinessSelected={isBusinessSelected}
          userObject={userObject}
          setChangeBusinessType={setChangeBusinessType}
          selectBusinessTypeProp={selectBusinessTypeProp}
          allowForceBackNav={allowForceBackNav}
          setAllowForceBackNav={setAllowForceBackNav}
        />
      )}
      {pageNumber === 5 && (
        <OnboardingScreen4
          handlePrev={handlePrev}
          handleNext={handleNext}
          userObject={userObject}
          selectBusinessTypeProp={selectBusinessTypeProp}
          setAllowForceBackNav={setAllowForceBackNav}
        />
      )}
      {pageNumber === 6 && (
        <OnboardingScreen5
          handleNext={() => handleNext()}
          handlePrev={handlePrev}
        />
      )}
      {pageNumber === 7 && (
        <OnboardingFinalScreenNew
          handleNext={handleNext}
          handlePrev={handlePrev}
        />
      )}
    </>
  );
}

export default Onboarding;
