import React, { useState, useContext } from "react";
import "./Onboarding.css";
import "../../App.css";
import { BiArrowBack } from "react-icons/bi";
import { FaUserAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Button, Container, OverlayTrigger, Popover } from "react-bootstrap";
import { useEffect } from "react";
import { getOnboardingData, setOnboardingData } from "../../Actions/Onboarding";
import { checkAuth, refreshToken } from "../../Actions/Auth";
import { userStatus, usersData } from "../../App";
import ErrorModal from "../Modals/ErrorModal";
import { IoIosMail, IoMdCall } from "react-icons/io";
import ButtonLoading1 from "../Spinners/ButtonLoading1";
import { height } from "@mui/system";

function OnboardingScreen2({
  handlePrev,
  handleBusiness,
  isBusinessSelected,
  handleNext,
  setVideoUrl,
  changeBusinessType,
  setChangeBusinessType,
  setSelectBusinessTypeProp,
  selectBusinessTypeProp,
}) {
  const navigate = useNavigate();
  const [availableBusinessTypes, setAvailableBusinessTypes] = useState([]);
  const { setShowMaintenance, userData } = useContext(usersData);
  const {setUserLoggedIn } =useContext(userStatus);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [selectedTag, setSelectedTag] = useState("");
  //const { mobileNo } = userData;
  const [userObject, setUserObject] = useState({});
  const [showSetBusinessScreen, setShowSetBusinessScreen] = useState(false);
  const [showHelpOverlay, setShowHelpOverlay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shimmer, setShimmer] = useState(false);

  const hadleShowHelpOverlay = () => {
    setShowHelpOverlay((val) => !val);
  };

  const getData = () => {
    getOnboardingData("getUserData", {})
      .then((res) => {
        // console.log(res)
        setUserObject(res.data.data);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          refreshToken().then((res) => {
            getData();
          });
        } else if (error?.response?.status == 502) {
          setShowMaintenance(true);
        }
      });
  };

  useEffect(() => {
    let userData = localStorage.getItem("userData");
    userData = JSON.parse(userData);
    if (userData.businessType === null) {
      setShowSetBusinessScreen(true);
    } else if (changeBusinessType) {
      setShowSetBusinessScreen(true);
    } else {
      setChangeBusinessType(false);
      setShowSetBusinessScreen(false);
      handleNext();
    }
  }, []);

  const handlePostBusinessType = () => {
    postBusinessType();

    // handleNext()
  };

  const getBusinessTypes = () => {
    //update the shimmer state
    setShimmer(true);
    getOnboardingData("getBusinessType", {})
      // .then((res) => console.log(res?.data?.data))
      .then((res) => {
        setAvailableBusinessTypes(res?.data?.data);
        //update the shimmer state
        setShimmer(false);
      })
      .catch((error) => {
        //update the shimmer state
        setShimmer(false);
        if (error.response.status == 401) {
          refreshToken().then((res) => {
            getBusinessTypes();
          });
        } else if (
          error.response.status == 502 ||
          error.response.status == 503
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error.response.status == 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong!",
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
          });
        }
      });
  };

  const postBusinessType = () => {
    //update the button loading state
    setIsLoading(true);
    const params = {
      businessTag: selectedTag,
    };
    setOnboardingData("setBusinessType", params, {})
      .then(async (res) => {
        //update the button loading state
        setIsLoading(false);
        setSelectBusinessTypeProp(selectedTag);
        setChangeBusinessType(false);
        setVideoUrl(res?.data?.data);
        const body = {
          mobileNumber:userData?. mobileNo,
        };
        await checkAuth(body).then((res) => {
          localStorage.setItem("userData", JSON.stringify(res.data.data.user));
        });
        getOnboardingData("getUserData", {}).then((res) => {
          localStorage.setItem("userObject", JSON.stringify(res.data.data));
        });
        if (selectedTag === "PHARMACY") {
          setUserLoggedIn(true);
          navigate("/home");

        } else handleNext();
      })
      .catch((error) => {
        //update the button loading state
        setIsLoading(false);
        if (error.response.status == 401) {
          refreshToken().then((res) => {
            postBusinessType();
          });
        } else if (
          error.response.status == 502 ||
          error.response.status == 503
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error.response.status == 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong!",
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error.response.data.error.error,
          });
        }
      });
  };

  useEffect(() => {
    availableBusinessTypes.map((item) => {
      if (item.title === selectBusinessTypeProp) {
        setSelectedTag(item.tag);
      }
    });
  }, [selectBusinessTypeProp]);

  useEffect(() => {
    getBusinessTypes();
    if(!userData?.mobileNo){
      localStorage.setItem("screenCount", 0);
      localStorage.removeItem("currentScreen");
      localStorage.removeItem("currentScreenStartTime");
      localStorage.removeItem("userData");
      localStorage.removeItem("userAuth");
      localStorage.removeItem("userObject");
      localStorage.removeItem("onBehalfRetCode");
      
      navigate("/login");
    }
    // getData();
  }, []);

  if (showSetBusinessScreen) {
    return (
      <>
        <ErrorModal
          show={errorModal.show}
          content={errorModal.content}
          apiData={errorModal?.apiData}
          onHide={() => {
            setErrorModal({ show: false, content: "" });
          }}
        />
        {showSetBusinessScreen && (
          <Container fluid className="onboarding">
            <Container fluid className="parentDiv_onboarding">
              <div className="header-nav">
                <BiArrowBack
                  size="22px"
                  color="white"
                  className="header-nav-icon"
                  onClick={handlePrev}
                />
                <p className="header-nav-title">Select your business</p>
                {/* <p className="help-tag">Help</p> */}
                {/* {showHelpOverlay && ( */}
                <OverlayTrigger
                  trigger="click"
                  key={"bottom"}
                  placement={"bottom"}
                  overlay={
                    <Popover id={`popover-positioned-bottom`}>
                      <Popover.Header as="h3">
                        <a
                          href="tel:+918035018025"
                          className="text_decoration_none color_primary"
                        >
                          <IoMdCall className="color_secondary" /> &nbsp;&nbsp;
                          8035018025
                        </a>
                      </Popover.Header>
                      <Popover.Body>
                        <a
                          href="mailTo:support@saveo.in"
                          className="text_decoration_none color_primary"
                        >
                          <IoIosMail className="color_secondary" /> &nbsp;&nbsp;
                          support@saveo.in
                        </a>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <div className="help-tag">Help</div>
                </OverlayTrigger>
                {/* )} */}
              </div>
              {!shimmer && (
                <Container fluid className="select-business-section">
                  <Container fluid className="onboard-card-container" >
                    {availableBusinessTypes?.map((item, index) => {
                      return (
                        <div
                          key={index + 1}
                          style={
                            selectBusinessTypeProp === `${item.title}`
                              ? { backgroundColor: "#DADAFD" }
                              : { backgroundColor: "white" }
                          }
                          className="onboard-card"
                          onClick={() => handleBusiness(`${item.title}`)}
                        >
                          <div
                            style={
                              selectBusinessTypeProp === `${item.title}`
                                ? { display: "inline-block" }
                                : { display: "none" }
                            }
                            className="active-tag"
                          ></div>
                          <div
                            style={
                              selectBusinessTypeProp === `${item.title}`
                                ? { backgroundColor: "white" }
                                : { backgroundColor: "#DADAFD" }
                            }
                            className="onboard-img-container"
                          >
                            {/* <FaUserAlt size="1.5rem" className="onboard-img" /> */}
                            <img
                              src={item?.imageUrl}
                              alt={item?.title}
                              className="onboard-img"
                            />
                          </div>
                          <div className="onboard-card-content">
                            <h6
                              className="onboard-card-title"
                              style={
                                selectBusinessTypeProp === `${item.title}`
                                  ? { color: "#4B4495" }
                                  : { color: "#6D6D6D" }
                              }
                            >
                              {item.title}
                            </h6>
                            <p
                              className="onboard-card-text"
                              style={
                                selectBusinessTypeProp === `${item.title}`
                                  ? { color: "#6D789A" }
                                  : { color: "#9399A4" }
                              }
                            >
                              {item.description}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </Container>
                  <div className="next-button-container" >
                    <Button
                      disabled={!setSelectBusinessTypeProp.length > 0}
                      className="bg_secondary button border_none"
                      onClick={handlePostBusinessType}
                      style={{ minHeight: "3.5rem"}}
                    >
                      {isLoading && <ButtonLoading1 />}
                      {!isLoading && "Next"}
                    </Button>
                  </div>
                </Container>
              )}
              {shimmer && (
                <Container fluid className="select-business-section">
                  <Container fluid className="onboard-card-container">
                    {[...Array(3)].map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="onboard-card shimmer-skeleton"
                        >
                          <div className='animateShimmer'></div>
                          <div>
                            <div className='animateShimmer'></div>
                            <div className='animateShimmer'></div>
                          </div>

                        </div>
                      );
                    })}
                  </Container>
                </Container>
              )}
            </Container>
          </Container>
        )}
      </>
    );
  } else {
    return <></>;
  }
}

export default OnboardingScreen2;
