import React, {
  useState,
  Fragment,
  useEffect,
  useContext,
  useRef,
} from "react";
import { Spinner } from "react-bootstrap";
import "../Onboard/Login.css";
import "../../App.css";
import { SignUp, getOTP, verifyOTP, checkAuth } from "../../Actions/Auth";
import { userStatus, usersData, userObjectContext } from "../../App";
import { useNavigate } from "react-router";
import "./Onboarding.css";
import { getOnboardingData, setOnboardingData } from "../../Actions/Onboarding";
import { Common_Event_Hit } from "../../Actions/UserEvents";
import { Moengage } from "../../environments";
import loginbot from "../../Images/json/Loginbot.json";
import Lottie from "react-lottie-player";
import Saveologin from "../../Images/svg/Saveo_login_logo.svg";
import Saveologo from "../../Images/jpg/Onboarding-Slide.jpg";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const Login = () => {
  const OTP = {
    val1: "",
    val2: "",
    val3: "",
    val4: "",
    val5: "",
    val6: "",
  };

  const { userObject, setUserObject } = useContext(userObjectContext);
  const navigate = useNavigate();
  const [loginOTP, setLoginOTP] = useState({
    status: false,
    value: null,
    expiryTime: 0,
  });
  const [mobileNo, setMobileNo] = useState("");
  const [password, setPassword] = useState("");

  const { userLoggedIn, setUserLoggedIn, setIsUserEnabled } =
    React.useContext(userStatus);
  const { setShowMaintenance,userData, setUserData,setErrorHandler, setCreditStatus,setOnBehalfStatus
  } =
    useContext(usersData);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [singUpSpinner, setSignUpSpinner] = useState(false);
  const [newpassword, setNewpassword] = useState({
    newPassword: "",
    retypePassword: "",
  });
  const [passwordVerification, setPasswordVerification] = useState(false);
  const [pinCodeEntered, setPinCodeEntered] = useState(false);
  const [pinCode, setPinCode] = useState("");
  const [flow, setFlow] = useState("singin_pass");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordMismatchError, setPasswordMismatchError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showUserExistsError, setShowUserExistsError] = useState(false);
  // loginOtpRef.current = loginOTP
  const loginOtpRef = useRef();
  const submitPinButtonRef = useRef(null);
  const confirmSignInButtonRef = useRef(null);
  const newPasswordInputRef = useRef(null);
  const retypePasswordInputRef = useRef(null);
  const mobileNumberInputRef = useRef();
  const passwordInputRef = useRef();

  const inputRef1 = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const inputRef4 = useRef();
  const inputRef5 = useRef();
  const inputRef6 = useRef();

  const arr = [
    inputRef1,
    inputRef2,
    inputRef3,
    inputRef4,
    inputRef5,
    inputRef6,
  ];

  const handleKey = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      // Check which element triggered the Enter key press using 'event.target'.
      // If the Enter key was pressed on the 'confirmSignInButtonRef',submitPinButtonRef,retypePasswordInputRef, element:
      if (event.target === confirmSignInButtonRef.current) {
        confirmSignInButtonRef.current.focus();
        confirmSignInButtonRef.current.click();
      }
      if (event.target === submitPinButtonRef.current) {
        submitPinButtonRef.current.focus();
        postZipCode();
      } else if (event.target === retypePasswordInputRef.current) {
        retypePasswordInputRef.current.focus();
      }
    }
  };

  //input field refs

  //on key down handler for the mobile number input field
  const handleTabNavigation = (e) => {
    // console.log("tab navigation hit from the login screen");
    //handle on key down for the input field
    //mac -> tab -> keycode : 9

    if (
      e.keyCode === 13 &&
      e.target.value.length === 10 &&
      (flow === "signin_otp" || flow === "reset_pass" || flow === "signup")
      //flow === "signin_otp"
    ) {
      getotp();
    }
    if (
      (e.keyCode === 9 || e.keyCode === 13) &&
      flow === "singin_pass" &&
      document.activeElement === mobileNumberInputRef.current
    ) {
      //prevent event bubbling when ref is focussed
      e.preventDefault();
      e.stopPropagation();
      //only focus if the length of the number is equal to 10 digits
      if (e.target.value.length === 10) {
        passwordInputRef.current.focus();
        passwordInputRef.current.select();
      } else if (e.target.value.length < 10) {
        //handle error handshake when the number is less than 10 charater\s
      }
    }
  };

  const mobileInput = (e) => {
    let val = e.target.value;
    val = val.toString();
    if (val.length > 10) {
      val = val.substr(0, 10);
    }
    setMobileNo(val);

    if (val.length === 10) {
      setShowPasswordField(true);
    } else {
      setShowPasswordField(false);
    }
  };

  const passInput = (e) => {
    setPassword(e.target.value);
    if (e.keyCode === 13 && password?.length) {
      userSignUp();
    }
  };

  const setFocus = (inp) => {
    inp.current.focus();
  };

  const handleOtpNew = (e) => {
    if (e.keyCode === 13) {
      flow === "signup" || flow === "reset_pass"
        ? handleVerifyOTP()
        : userSignUp();
    }
    let maxlen = 1;
    const { value, id } = e.target;
    if (value.length > 1) {
      let x = 0;
      for (let i = +id; i < 6; i++) {
        let temp = value.substr(x, 1);
        arr[i].current.value = temp;
        setFocus(arr[i]);
        x++;
      }
    }
    if (e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37) {
      if (+id > 0) {
        setFocus(arr[+id - 1]);
        setErrorMsg("");
      }
    } else {
      if (arr.length - 1 > +id) {
        setFocus(arr[+id + 1]);
      }
    }
    constructOtp();
  };

  const clearOtpFields = () => {
    // a function to clear OTP input fields or reset OTP-related state.
    for (let i = 0; i < arr.length; i++) {
      arr[i].current.value = "";
    }
  };

  const forgotPassword = () => {
    let previousValue = { ...loginOTP };
    previousValue.status = false;
    setLoginOTP(previousValue);

    forgotPassword(true);
    setPasswordVerification(true);
  };

  const handlePinInputChange = (e) => {
    setPinCode(e.target.value);
  };

  const onChangeNewPassword = (event) => {
    setNewpassword({
      ...newpassword,
      newPassword: event.target.value,
    });
  };

  const onChangeRetypePassword = (event) => {
    setNewpassword({
      ...newpassword,
      retypePassword: event.target.value,
    });
  };

  const getotp = () => {
    setSignUpSpinner(true);
    setErrorMsg("");
    const params = {
      mobileNo: mobileNo,
    };

    getOTP(params)
      .then((res) => {
        setLoginOTP({
          status: true,
          value: null,
          expiryTime: res.data.data.otpExpirationTime,
        });
        // a function to clear OTP input fields or reset OTP-related state
        clearOtpFields();
      })
      .catch((error) => {
        setErrorHandler({
          show: true,
          screen: "login_screen",
          errorList: [{ error: error, code: "" }],
          fn: getotp, // Retry 'getotp' function in case of an error.
        });
      })
      .finally(() => {
        setSignUpSpinner(false);
      });
  };

  const constructOtp = () => {
    let str1 = "";
    arr.map((ref, index) => {
      if (ref.current) {
        str1 += ref.current.value;
      }
    });
    console.log("str1", str1);
    loginOtpRef.current = str1;
  };

  //get userObject and update the local storage and context
  const getUserObject = async () => {
    setSignUpSpinner(true);

    try {
      const res = await getOnboardingData("getUserData", {});
      console.log("res from verify otp api userObject", res?.data?.data);
      localStorage.setItem("userObject", JSON.stringify(res?.data?.data));
      //update the userObject context state
      setUserObject(res?.data?.data);
    } catch (error) {
      console.log(error);
      setSignUpSpinner(false);
      setErrorHandler({
        show: true,
        screen: "login_screen",

        errorList: [{ error: error, code: "" }],
        fn: function () {
          getUserObject();
        },
        onHide: function () {
          localStorage.removeItem("userData");
          localStorage.removeItem("userAuth");
          window.location.reload();
        },
      });
    }
  };

  const userSignUp = async () => {
    constructOtp();
    setSignUpSpinner(true);
    setErrorMsg("");
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "signup_tap",
      mobileNo: mobileNo,
      screen_name: localStorage.getItem("currentScreen"),
    });
    localStorage.removeItem("onBehalfRetCode");
    setOnBehalfStatus(false)
    if (loginOTP.status) {
      console.log("here");
      const params = {
        mobileNo: mobileNo,
        OTP: loginOtpRef.current,
      };
      await verifyOTP(params)
        .then((res) => {
          console.log("res from verify otp api userAuth", res);
          localStorage.setItem("userAuth", JSON.stringify(res.data));
          localStorage.setItem(
            "isPasswordSet",
            JSON.stringify(res.data.data.isPasswordSet)
          );
          setErrorMsg(""); //updates the userObject in the local storag
          getUserObject();
          const body = {
            mobileNumber: mobileNo,
          };
          checkAuth(body)
            .then(async (res) => {
              localStorage.setItem(
                "userData",
                JSON.stringify(res.data.data.user)
              );
              // save empty notification data into the local storage
              localStorage.setItem("notificationData", JSON.stringify([]));
              setUserData(res.data.data.user);
              setIsUserEnabled(res.data.data.user.enabled);
              setUserLoggedIn(true);
              setSignUpSpinner(false);
              // setErrorMsg("");
              Common_Event_Hit("DYNAMIC_EVENT", {
                eventName: "authenticated",
                screen_name: localStorage.getItem("currentScreen"),
              });
              let sessionCount = localStorage.getItem("sessionCount");
              localStorage.setItem("sessionCount", +sessionCount + 1);
              if (
                res?.data?.data?.user?.businessType &&
                (res.data.data.user.enabled ||
                  res.data.data.user.businessType[0] === "PHARMACY")
              ) {
                // update the user attributes for moengage
                let userData = res.data.data.user;
                Moengage.add_first_name(
                  userData?.vshopName || userData?.shopName
                );
                Moengage.add_email(userData?.vemailId || userData?.emailId);
                Moengage.add_mobile(userData?.vmobile || userData?.mobileNo);
                Moengage.add_unique_user_id(
                  userData?.vmobile || userData?.mobileNo
                );
                navigate("/home");
              } else {
                if (
                  res.data.data.user.enabled &&
                  res.data.data.user.businessType[0] !== "PHARMACY"
                )
                  navigate("/wholesale/home");
                //navigate("/onboarding");
                else {
                  // if  user does not meet specific conditions set appropriate state and error message.
                  setUserLoggedIn(false);
                  setSignUpSpinner(false);
                  if (flow === "signin_otp") {
                    setErrorMsg("! User Doesnot Exists");
                  }
                }
              }
            })
            .catch((error) => {
              console.log(error);
              setSignUpSpinner(false);
              if (error?.response?.data?.error?.message) {
                console.log(error.response.data);
                setErrorMsg(" ! " + error.response.data.error.message);
              } else {
                setErrorMsg(" !something went wrong.");
              }
            });
        })
        .catch((error) => {
          console.log(error);
          setSignUpSpinner(false);
          if (error?.response?.data?.error?.message) {
            console.log(error.response.data);
            setErrorMsg(" ! " + error.response.data.error.message);
          } else {
            setErrorMsg(" !something went wrong.");
          }
        });
    } else {
      //  setErrorMsg("");
      const params = {
        mobileNo: mobileNo,
        password: passwordInputRef.current.value,
      };

      setSignUpSpinner(true);
      console.log(password);
      await SignUp(params)
        .then(async (res) => {
          localStorage.setItem("userAuth", JSON.stringify(res.data));
          localStorage.setItem(
            "isPasswordSet",
            JSON.stringify(res.data.data.isPasswordSet)
          );

          //update the userObject in local storage
          getUserObject();
          // setErrorMsg("");
          const body = {
            mobileNumber: mobileNo,
          };
          setSignUpSpinner(true);

          checkAuth(body).then(async (res) => {
            localStorage.setItem(
              "userData",
              JSON.stringify(res.data.data.user)
            );
            // save empty notification data into the local storage
            localStorage.setItem("notificationData", JSON.stringify([]));

            // update the user attributes for moengage
            let userData = res.data.data.user;
            setUserData(res.data.data.user);
            Moengage.add_first_name(userData?.vshopName || userData?.shopName);
            Moengage.add_email(userData?.vemailId || userData?.emailId);
            Moengage.add_mobile(userData?.vmobile || userData?.mobileNo);
            Moengage.add_unique_user_id(
              userData?.vmobile || userData?.mobileNo
            );

            setUserLoggedIn(true);
            // setErrorMsg("");
            Common_Event_Hit("DYNAMIC_EVENT", {
              eventName: "authenticated",
              screen_name: localStorage.getItem("currentScreen"),
            });
            let sessionCount = localStorage.getItem("sessionCount");
            localStorage.setItem("sessionCount", +sessionCount + 1);
            if (
              res?.data?.data?.user?.enabled ||
              (res?.data?.data?.user?.businessType &&
                res?.data?.data?.user?.businessType[0] === "PHARMACY")
            ) {
              navigate("/home");
            } else {
              //update the userObject in the localStorage
              getUserObject();
              // navigate("/onboarding");
              if (
                res.data.data.user.enabled &&
                res.data.data.user.businessType[0] !== "PHARMACY"
              )
                navigate("/wholesale/home");
              else {
                setUserLoggedIn(false);
                setSignUpSpinner(false);
                setErrorMsg("!user doesnot exists");
              }
            }
          });
        })
        .catch((error) => {
          setSignUpSpinner(false);
          if (error?.response?.data?.error?.message) {
            setErrorMsg(" ! " + error.response.data.error.message);
          } else {
            setErrorMsg(" ! Something went wrong..");
          }
        });
    }
  };

  const handleVerifyOTP = async () => {
    constructOtp();
    setSignUpSpinner(true);

    setErrorMsg("");
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "signup_tap",
      mobileNo: mobileNo,
      screen_name: localStorage.getItem("currentScreen"),
    });

    if (loginOTP.status) {
      const params = {
        mobileNo: mobileNo,
        OTP: loginOtpRef.current,
      };
      verifyOTP(params)
        .then((res) => {
          setNewpassword({
            newPassword: "",
            retypePassword: "",
          });
          setSignUpSpinner(false);
          localStorage.setItem("userAuth", JSON.stringify(res.data));
          localStorage.setItem(
            "isPasswordSet",
            JSON.stringify(res.data.data.isPasswordSet)
          );
          // Check if the user exists
          const body = {
            mobileNumber: mobileNo,
          };
          checkAuth(body)
            .then(async (res) => {
              setUserData(res.data.data.user);
              setIsUserEnabled(res.data.data.user.enabled);
              //  Check user's business type
              if (!res?.data?.data?.user?.businessType?.length) {
                // If the user does not exist and it's a signup flow, set password verification to true
                // i.e enternewpassword and retype newpassword fields
                if (flow === "signup") {
                  setPasswordVerification(true);
                }
                if (flow === "reset_pass") {
                  // If it's a password reset flow, handle user not found error
                  setShowUserExistsError(false);
                  setUserLoggedIn(false);
                  setErrorMsg("! User Doesnot Exists"); // Set the error message here
                }

                setSignUpSpinner(false);
              } else {
                if (flow === "reset_pass") {
                  // If it's a password reset flow, and user is found then  set password verification to true
                  setPasswordVerification(true);
                  setPinCodeEntered(false);
                }
                if (flow === "signup") {
                  // If it's a signup flow, handle user exists error
                  setShowUserExistsError(true);
                  setErrorMsg(" "); //reset the error
                }
              }
            })
            .catch((error) => {
              //handle errors
              localStorage.clear();
              setSignUpSpinner(false);
              if (error?.response?.data?.error?.message) {
                setErrorMsg(" ! " + error.response.data.error.message);
              } else {
                setErrorMsg(" ! Something went wrong..");
              }
            });
        })
        .catch((error) => {
          setSignUpSpinner(false);
          if (error?.response?.data?.error?.message) {
            setErrorMsg(" ! " + error.response.data.error.message);
          } else {
            setErrorMsg(" ! Something went wrong..");
          }
        });
    }
  };

  const handleNewPassword = () => {
    setSignUpSpinner(true);
    // Reset the password mismatch error and set passwords to match initially.

    setPasswordMismatchError("");
    setPasswordsMatch(true);

    if (newpassword.newPassword !== newpassword.retypePassword) {
      // If passwords don't match, set the signup spinner to false, set passwords to not match,
      setSignUpSpinner(true);
      setPasswordsMatch(false);
      setPasswordMismatchError(" ! Passwords do not match....");
      setSignUpSpinner(false);
      return;
    }

    const params = {
      mobileNo: mobileNo,
      password: newpassword.newPassword,
      updatedBy: userData?.shopName + "_" + userData?.mobileNo,

      ...(flow === "reset_pass" && { reason: "forgotPassword" }),

      // Include a reason for resetting the password if the flow is reset_pass.
    };

    setSignUpSpinner(true);

    setOnboardingData("addUserCredentials", params, {})
      .then((res) => {
        localStorage.setItem("isPasswordSet", JSON.stringify(true));
        console.log(res);
        if (flow === "signup") {
          setErrorMsg("");
          setPinCodeEntered(true);
        } else if (flow === "reset_pass") {
          setPasswordResetSuccess(true);
        }

        setSignUpSpinner(false);
      })
      .catch((error) => {
        setErrorHandler({
          show: true,
          screen: "login_screen",
          errorList: [{ error: error, code: "" }],
          fn: handleNewPassword,
        }).finally(() => {
          setSignUpSpinner(false);
        });
      });
  };

  const postZipCode = () => {
    setSignUpSpinner(true);

    const params = {
      pincode: pinCode,
    };

    setOnboardingData("setPincode", params, {})
      .then((res) => {
        console.log(res);
        const body = {
          mobileNumber: mobileNo,
        };

        checkAuth(body)
          .then((res) => {
            console.log("inside check auth in zip screen");
            localStorage.setItem(
              "userData",
              JSON.stringify(res.data.data.user)
            );
            navigate("/onboarding");
          })
          .catch((error) => {
            setSignUpSpinner(false);
            if (error?.response?.data?.error?.message) {
              setErrorMsg(" ! " + error.response.data.error.message);
            } else {
              setErrorMsg(" ! Something went wrong..");
            }
          });
      })
      .catch((error) => {
        setErrorHandler({
          show: true,
          screen: "login_screen",
          errorList: [{ error: error, code: "" }],
          fn: postZipCode,
        }).finally(() => {
          setSignUpSpinner(false);
        });
      });
  };

  useEffect(() => {
    // Reset various state variables and values to their initial states or empty values.
    {
      setMobileNo(" ");
      setPassword(" ");
      setLoginOTP({ status: false });
      setPasswordMismatchError("");
      setShowUserExistsError(false);
      setShowPassword(false);
      setNewpassword({ newPassword: "", retypePassword: "" });
      setSignUpSpinner(false);
      setPinCodeEntered(false);
      setPinCode("");
      setMinutes(0);
      setSeconds(0);
      setErrorMsg(" ");
      setPasswordVerification(false);
      setPasswordResetSuccess(false);
    }
  }, [flow]);

  useEffect(() => {
    //converts the otp expiry time from getOTP Api to seconds and updates the corresponding state
    // console.log('loginOTP',loginOTP)
    const time = Math.floor(loginOTP.expiryTime / 1000);
    let min = Math.floor(time / 60);
    let sec = Math.floor(time % 60);
    setMinutes(min);
    setSeconds(sec);
  }, [loginOTP]);

  // otp timer logic using setInterval
  useEffect(() => {
    const OTPTimer = setInterval(() => {
      if (seconds > 0) setSeconds((prevSeconds) => prevSeconds - 1);
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(OTPTimer);
          setLoginOTP({ ...loginOTP, expiryTime: 0 });
        } else {
          setMinutes((prevMin) => prevMin - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => {
      clearInterval(OTPTimer);
    };
  });

  useEffect(() => {
    let authData = localStorage.getItem("userAuth");
    localStorage.setItem("currentScreen", "login_screen");
    localStorage.removeItem("currentScreenBannerData");
    setCreditStatus({ show: false, data: null });
  
    let userData = localStorage.getItem("userData");
    let parsedData = JSON.parse(userData);
    if (
      authData &&
      JSON.parse(authData).data.jwtToken &&
      userData &&
      "businessType" in parsedData
    ) {
      setUserLoggedIn(true);
    } else {
      setUserLoggedIn(false);
    }

    if (userData && userData?.length && "businessType" in parsedData) {
      navigate("/home");
    }else{
      localStorage.clear();
    }
  }, []);

  return (
    <>
      {!userLoggedIn && (
        <div className="login-cntr">
          <div className="logo-container">
            <img
              src={Saveologo}
              id="img"
              className="saveo1_logo"
              alt="saveo1_logo"
            />
          </div>

          <div className="login-section">
            <img src={Saveologin} className="saveo_logo" alt="saveo_logo" />
            <div className="login-panel">
              <div className="login-header-cntr">
                <div className="login-header">
                  <p>Welcome {flow === "signup" ? "Saviour!" : "Back"}</p>
                  <h3>{flow === "signup" ? "Sign up" : "Saviour!"}</h3>
                </div>

                <div>
                  <Lottie
                    className="login_bot"
                    loop
                    animationData={loginbot}
                    play
                    style={{ width: "110px", height: "auto" }}
                  />
                </div>
              </div>

              {flow === "singin_pass" && (
                <>
                  <div className="login-inpt-cntr">
                    <div>
                      <label> Enter registered phone number</label>
                      <div className="login-inpt-box">
                        <p>+91</p>
                        <input
                          className="phn-inpt"
                          autoFocus
                          placeholder="730******56"
                          aria-label="Phone Number"
                          type="number"
                          onChange={mobileInput}
                          onKeyDown={handleTabNavigation}
                          size={10}
                          maxLength={10}
                          value={mobileNo}
                          ref={mobileNumberInputRef}
                        />
                      </div>
                    </div>

                    <Fragment>
                      <label>Enter password</label>
                      <div className="login-inpt-box">
                        <input
                          className="inpt-pass"
                          placeholder="Enter Password"
                          aria-label="Password"
                          type={showPassword ? "text" : "password"}
                          onKeyDown={passInput}
                          onFocus={() => {
                            setErrorMsg(false);
                          }}
                          disabled={!showPasswordField}
                          ref={passwordInputRef}
                        />
                        <span
                          className="password-toggle"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <AiOutlineEyeInvisible />
                          ) : (
                            <AiOutlineEye />
                          )}
                        </span>
                      </div>

                      {errorMsg && (
                        <div className="error-message">{errorMsg}</div>
                      )}

                      <div className="pass-link-wrapper">
                        <p
                          className="pass-link"
                          onClick={() => setFlow("reset_pass")}
                        >
                          Forgot your password?
                        </p>
                      </div>
                    </Fragment>
                  </div>

                  <div>
                    <button
                      className="login-btn"
                      onClick={userSignUp}
                      disabled={mobileNo?.length < 10 || !password?.length}
                    >
                      {singUpSpinner ? (
                        <Spinner
                          animation="border"
                          className="color_primary spinner"
                          size="sm"
                        />
                      ) : (
                        "Sign in"
                      )}
                    </button>

                    <p
                      className="login-cta"
                      onClick={() => setFlow("signin_otp")}
                    >
                      Sign in with OTP
                    </p>
                  </div>
                </>
              )}

              {flow === "signin_otp" && (
                <>
                  {!userLoggedIn && errorMsg === "! User Doesnot Exists" ? (
                    <div className="error-message1">
                      {errorMsg}

                      <p
                        className="input-sign"
                        onClick={() => setFlow("signup")}
                      >
                        Click Here To !!! <span>Sign up</span>
                      </p>
                    </div>
                  ) : (
                    <div className="login-inpt-cntr">
                      <label> Enter registered phone number</label>
                      <div className="login-inpt-box">
                        <p>+91</p>
                        <input
                          className="phn-inpt"
                          autoFocus
                          placeholder="730******56"
                          aria-label="Phone Number"
                          type="number"
                          onChange={mobileInput}
                          onKeyDown={handleTabNavigation}
                          size={10}
                          maxLength={10}
                          value={mobileNo}
                          ref={mobileNumberInputRef}
                        />
                      </div>
                      {loginOTP.status && (
                        <Fragment>
                          <label>Enter OTP</label>
                          {/* Add multiple input fields below */}
                          <div className="otp-inputs-container">
                            {[...Array(6)].map((_, index) => {
                              return (
                                <input
                                  key={"otp" + index}
                                  autoFocus={index === 0}
                                  ref={arr[index]}
                                  id={`${index}`}
                                  name={`val${index + 1}`}
                                  aria-label="OTP"
                                  type="number"
                                  maxLength={1}
                                  onKeyUp={handleOtpNew}
                                  className="zip-item"
                                />
                              );
                            })}
                          </div>

                          <div className="otp-timer-container">
                            {minutes === 0 && seconds === 0 ? (
                              <p className="resend-otp" onClick={getotp}>
                                Resend OTP
                              </p>
                            ) : (
                              <p className="resend-otp-disabled">
                                Resend OTP in{" "}
                                <span
                                  style={{
                                    color: "rgb(73, 88, 170)",
                                    fontSize: "14px",
                                  }}
                                >
                                  0{minutes}:
                                  {seconds < 10 ? `0${seconds}` : seconds}
                                </span>
                              </p>
                            )}
                          </div>
                        </Fragment>
                      )}
                      <div>
                        {!loginOTP.status && (
                          <button
                            className="login-btn"
                            onClick={getotp}
                            disabled={mobileNo?.length < 10}
                          >
                            {singUpSpinner ? (
                              <Spinner
                                animation="border"
                                className="color_primary spinner"
                                size="sm"
                              />
                            ) : (
                              "Send OTP"
                            )}
                          </button>
                        )}
                        {errorMsg && flow === "signin_otp" && (
                          <div className="error-message">{errorMsg}</div>
                        )}
                        {loginOTP.status && (
                          <button
                            className="login-btn"
                            onClick={userSignUp}
                            disabled={
                              mobileNo?.length < 10 ||
                              loginOtpRef.current?.length < 6 ||
                              loginOtpRef?.current === null ||
                              !loginOtpRef?.current
                            }
                          >
                            {singUpSpinner ? (
                              <Spinner
                                animation="border"
                                className="color_primary spinner"
                                size="sm"
                              />
                            ) : (
                              "Verify & Sign in"
                            )}
                          </button>
                        )}
                        <p
                          className="login-cta"
                          onClick={() => setFlow("singin_pass")}
                        >
                          Sign in with password
                        </p>
                      </div>
                    </div>
                  )}
                </>
              )}
              {(flow === "signup" || flow === "reset_pass") && (
                <>
                  {/*Display an error message if 'showUserExistsError' is true. */}

                  {showUserExistsError ? (
                    <div className="error-message1">
                      ! User already exists..
                      <p
                        className="input-sign"
                        onClick={() => setFlow("singin_pass")}
                      >
                        Click Here To!!! <span>Sign in</span>
                      </p>
                    </div>
                  ) : // Display a success message if 'passwordResetSuccess' is true.

                  passwordResetSuccess ? (
                    <div className="success-message">
                      Your password has been successfully reset...!
                      <p
                        className="input-sign"
                        onClick={() => setFlow("singin_pass")}
                      >
                        Please Proceed To !!! <span>Sign in</span>
                      </p>
                    </div>
                  ) : // Display an error message if 'flow' is 'reset_pass', user is not logged in, and 'errorMsg' is "! User Doesnot Exists".

                  flow === "reset_pass" &&
                    !userLoggedIn &&
                    errorMsg === "! User Doesnot Exists" ? (
                    <div className="error-message1">
                      {errorMsg}
                      <p
                        className="input-sign"
                        onClick={() => setFlow("signup")}
                      >
                        Click Here To <span>Sign up</span>
                      </p>
                    </div>
                  ) : (
                    <div className="login-inpt-cntr">
                      {/*Display the mobile number input if 'passwordVerification' is false.*/}
                      {!passwordVerification && (
                        <>
                          <label> Enter registered phone number</label>

                          <div className="login-inpt-box">
                            <p>+91</p>
                            <input
                              className="phn-inpt"
                              disabled={loginOTP.status}
                              autoFocus
                              placeholder="730******56"
                              aria-label="Phone Number"
                              type="number"
                              onChange={mobileInput}
                              onKeyDown={handleTabNavigation}
                              size={10}
                              maxLength={10}
                              value={mobileNo}
                              ref={mobileNumberInputRef}
                            />
                          </div>
                        </>
                      )}
                      {/* Display OTP input fields if 'loginOTP.status' is true and 'passwordVerification' is false.  */}

                      {loginOTP.status && !passwordVerification && (
                        <Fragment>
                          <label>Enter OTP</label>
                          {/* add multiple input fields below */}
                          <div className="otp-inputs-container">
                            {[...Array(6)].map((_, index) => {
                              return (
                                <input
                                  key={"otp" + index}
                                  autoFocus={index === 0}
                                  ref={arr[index]}
                                  id={`${index}`}
                                  name={`val${index + 1}`}
                                  aria-label="OTP"
                                  type="number"
                                  maxLength={1}
                                  onKeyUp={handleOtpNew}
                                  className="zip-item"
                                />
                              );
                            })}
                          </div>
                          <div className="otp-timer-container">
                            {minutes === 0 && seconds === 0 ? (
                              <p className="resend-otp" onClick={getotp}>
                                Resend OTP
                              </p>
                            ) : (
                              <p className="resend-otp-disabled">
                                Resend OTP in{" "}
                                <span
                                  style={{
                                    color: "rgb(73, 88, 170)",
                                    fontSize: "14px",
                                  }}
                                >
                                  0{minutes}:
                                  {seconds < 10 ? `0${seconds}` : seconds}
                                </span>
                              </p>
                            )}
                          </div>
                        </Fragment>
                      )}
                      {/*Display OTP input fields if 'loginOTP.status' is true and 'passwordVerification' is false.*/}

                      {passwordVerification && !pinCodeEntered && (
                        <>
                          <label>Enter new password</label>
                          <div className="login-inpt-box">
                            <input
                              className="inpt-pass"
                              ref={newPasswordInputRef}
                              type={showPassword ? "text" : "password"}
                              value={newpassword.newPassword}
                              onChange={onChangeNewPassword}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  retypePasswordInputRef.current.focus();
                                }
                              }}
                              onFocus={() => {
                                setPasswordsMatch(true);
                                setPasswordMismatchError("");
                              }}
                            />
                            <span
                              className="password-toggle"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <AiOutlineEyeInvisible />
                              ) : (
                                <AiOutlineEye />
                              )}
                            </span>
                          </div>

                          <label>Retype new password</label>
                          <div className="login-inpt-box">
                            <input
                              className="inpt-pass"
                              ref={retypePasswordInputRef}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  confirmSignInButtonRef.current.focus();
                                }
                              }}
                              type={showPassword ? "text" : "password"}
                              value={newpassword.retypePassword}
                              onChange={onChangeRetypePassword}
                              onFocus={() => {
                                setPasswordsMatch(true);
                                setPasswordMismatchError("");
                              }}
                            />
                            <span
                              className="password-toggle"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <AiOutlineEyeInvisible />
                              ) : (
                                <AiOutlineEye />
                              )}
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            {/*Display an error message if 'passwordsMatch' is false. */}

                            {!passwordsMatch && (
                              <p className="error-message">
                                {passwordMismatchError}
                              </p>
                            )}
                          </div>
                        </>
                      )}
                      {/* Display the PIN code input field if 'pinCodeEntered' is true. */}

                      {pinCodeEntered && (
                        <div className="login-header">
                          <div className="login-inpt-cntr">
                            <label>Enter Pin Code</label>
                            <div className="login-inpt-box">
                              <input
                                className="login-inpt-box"
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                    submitPinButtonRef.current.focus();
                                  }
                                }}
                                onChange={handlePinInputChange}
                                type="number"
                                placeholder="Enter Pin Code"
                              />
                            </div>

                            <button
                              className="login-btn"
                              onClick={postZipCode}
                              ref={submitPinButtonRef}
                              onKeyUp={handleKey}
                              disabled={pinCode.length !== 6}
                            >
                              {singUpSpinner ? (
                                <Spinner
                                  animation="border"
                                  className="color_primary spinner"
                                  size="sm"
                                />
                              ) : (
                                "Submit Pin"
                              )}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {/*   Display an error message if 'errorMsg' is present and not "! User Doesnot Exists". */}

                  {errorMsg &&
                    (flow === "signup" || flow === "reset_pass") &&
                    errorMsg !== "! User Doesnot Exists" && (
                      <div className="error-message">{errorMsg}</div>
                    )}
                  {/*Display buttons and links based on various conditions. */}

                  {!showUserExistsError &&
                    !passwordResetSuccess &&
                    errorMsg !== "! User Doesnot Exists" && (
                      <div>
                        {!loginOTP.status && !passwordVerification && (
                          <button
                            className="login-btn"
                            onClick={getotp}
                            disabled={mobileNo?.length < 10 || singUpSpinner}
                          >
                            {singUpSpinner ? (
                              <Spinner
                                animation="border"
                                className="color_primary spinner"
                                size="sm"
                              />
                            ) : (
                              "Send OTP"
                            )}
                          </button>
                        )}

                        {loginOTP.status && !passwordVerification && (
                          <button
                            className="login-btn"
                            onClick={handleVerifyOTP}
                            disabled={
                              loginOtpRef.current?.length < 6 ||
                              loginOtpRef?.current === null ||
                              !loginOtpRef?.current ||
                              singUpSpinner
                            }
                          >
                            {singUpSpinner ? (
                              <Spinner
                                animation="border"
                                className="color_primary spinner"
                                size="sm"
                              />
                            ) : (
                              "Verify"
                            )}
                          </button>
                        )}

                        {passwordVerification && !pinCodeEntered && (
                          <button
                            className="login-btn"
                            onClick={handleNewPassword}
                            ref={confirmSignInButtonRef}
                            onKeyUp={handleKey}
                            disabled={
                              loginOtpRef?.current === null ||
                              newpassword.newPassword.length === 0 ||
                              newpassword.retypePassword.length === 0 ||
                              singUpSpinner
                            }
                          >
                            {singUpSpinner ? (
                              <Spinner
                                animation="border"
                                className="color_primary spinner"
                                size="sm"
                              />
                            ) : (
                              "Confirm "
                            )}
                          </button>
                        )}

                        <p
                          className="login-cta"
                          onClick={() => setFlow("singin_pass")}
                        >
                          Login with password
                        </p>
                      </div>
                    )}
                </>
              )}

              {(flow === "singin_pass" ||
                flow === "signin_otp" ||
                flow === "reset_pass") && (
                <p className="input-sign" onClick={() => setFlow("signup")}>
                  Don't have an account? <span>Sign up</span>
                </p>
              )}
              {flow === "signup" && (
                <p
                  className="input-sign"
                  onClick={() => setFlow("singin_pass")}
                >
                  Have an account? <span>Sign in</span>
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Login;
