import React, {useState, useEffect, useContext, Fragment} from "react";
import {Container, Row, Col, Accordion, Button, Spinner, Form} from "react-bootstrap";
import * as Scroll from "react-scroll";
import "../../App.css";
import "./Cart.css";

import {AiFillGift, AiOutlineInfoCircle} from "react-icons/ai";
import {BiChevronDown, BiChevronUp} from "react-icons/bi";
import {HiOutlineArrowNarrowLeft} from "react-icons/hi";
import {MdDelete} from "react-icons/md";
import {useNavigate} from "react-router-dom";
import {Cart_Actions} from "../../Actions/Cart";
import {checkAuth, refreshToken} from "../../Actions/Auth";
import {getScreen} from "../../Actions/Screens";
import {common_product_widget} from "../../Actions/Widgets";
import Slider from "../Sliders/Slider";
import {cartDataContext, usersData} from "../../App";
import CartCard from "./CartCard";
import DeleteProductModal from "../Modals/DeleteProductModal";
import EmptyCart from "../../Images/png/emptyCart.jpg";
import ErrorModal from "../Modals/ErrorModal";
import OrderMessageModal from "../Modals/orderMessageModal";
import {Common_Event_Hit} from "../../Actions/UserEvents";
import OrderSuccessModal from "../Modals/OrderSuccessModal";
import {creditOnboardingPopup} from "../../Actions/ApplyCredit";
import AcceptCreditAgreementModal from "../Modals/AcceptCreditAgreementModal";
import OrderInProgress from "../Modals/OrderInProgressModal";
import DeletingCartItemsModal from "../Modals/DeletingCartItemsModal";

var Element = Scroll.Element;
var scroller = Scroll.scroller;
var Events = Scroll.Events;

const Cart = () => {
  let navigate = useNavigate();
  const [cartDataIn, setCartDataIn] = useState();
  const [giftData, setGiftData] = useState([]);
  const [showSpinner, setShowSpinner] = useState({show: false, id: 0});
  const [widgetsData, setWidgetsData] = useState();
  const [recProducts, setRecProducts] = useState();
  const [orderAgain, setOrderAgain] = useState();
  const [screenShimmer, setScreenShimmer] = useState(false);
  const [recProductShimmer, setRecProductShimmer] = useState(false);
  const [orderAgainShimmer, setOrderAgainShimmer] = useState(false);
  const [cartShimmer, setCartShimmer] = useState(false);
  const {cartData, setCartData} = useContext(cartDataContext);
  const [shipments, setShipments] = useState([]);
  const [shipmentsShimmer, setShipmentsShimmer] = useState(false);
  const [shipmentsValueData, setShipmentsValueData] = useState();
  const [outOfStock, setOutOfStock] = useState();
  const [selectedPayment, setPayment] = useState();
  const [creditData, setCreditData] = useState();
  const [selectPaymentError, setSelectPaymentError] = useState(false);
  const [userData, setUserData] = useState();
  const [showDeleteItemsProgress, setDeleteItemProgress] = useState(false);
  const [onBehalfStatus, setOnBehalfStatus] = useState(false);
  const [selectedCredit, setSelectedCredit] = useState({
    referral: {selected: false, amount: 0},
    saveoCredit: {selected: false, amount: 0},
    subTotal: 0,
  });
  const {setShowMaintenance, setErrorHandler} = useContext(usersData);
  const [placeOrderError, setPlaceOrderError] = useState({
    show: false,
    data: "",
  });
  const [errorModal, setErrorModal] = useState({show: false, content: ""});
  const [priceDataDropdown, setPriceDataDropdown] = useState({
    show: false,
    key: "",
  });
  const [orderMessage, setOrderMessage] = useState({
    show: false,
    content: "",
    header: "",
  });
  const [animateCard, setAnimateCard] = useState({show: false, id: 0});
  const [showDeleteModal, setShowDeleteModal] = useState({
    show: false,
    id: 0,
  });
  const [showPlaceOrderSpinner, setPlaceOrderSpinner] = useState(false);
  const [showSuccessDialog, setSuccessDialog] = useState({
    show: false,
    content: "",
    creditLoanTapMessage: "",
  });
  const [agreementPopUpData, setAgreementPopUpData] = useState({});
  const [agreementPopUp, setAgreementPopUp] = useState({show: false});
  const [showOrderInProgress, setShowOrderInProgress] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showDeleteCart, setShowDeleteCart] = useState(false);
  const [genericsStatus, setGenericStatus] = useState([]);
  const [genericMessage, setGenericMessage] = useState();
  const [creditBlock, setCreditBlock]=useState(false)
  const cartView = () => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    const params = {};
    const body = {
      androidMap: {
        locale: "en",
        source: "pharmacyWeb",
        deviceId: null,
        appVersion: navigator.userAgent,
        deviceName: null,
        screen_name: "cart_screen",
        appVersionCode: "156",
      },
      retailerCode: data.retailerCode,
      retailerId: data.id,
    };
    Cart_Actions("VIEW_CART", params, body)
      .then(async (res) => {
        setScreenShimmer(false);
        setShipmentsShimmer(true);
        setCartShimmer(false);
        setCartData(res.data.data);
        setCartDataIn(res.data.data);
        setGiftData(res.data.data.cartCampaignGiftResponseList);
        let products = res.data.data.cartMedicineDetailResponseList;
        products && products.length > 0 && sortShipments(products);
        let genericStatusList = [];
        let genericMsg = "";
        let medNames = await products.map((item) => {
          if (item.genericAlternativeMessage) {
            genericMsg = item.genericAlternativeMessage;
            genericStatusList.push(item.medicineId);
          }
          return item.medicineName;
        });
        setGenericMessage(genericMsg);
        setGenericStatus(genericStatusList);
        Common_Event_Hit("DYNAMIC_EVENT", {
          eventName: "cart_opened",
          number_of_products: res.data.data.size,
          medicine_names: medNames,
          price: res.data.data.subTotal,
        });
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            cartView();
          });
        } else if (error?.response?.status === 502 || error?.response?.status === 503 || error?.response?.status === 504) {
          setShowMaintenance({status: true, apiData: error?.response});
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              cartView();
            },
          });
        } else if (error?.response?.status === 400) {
          setOrderMessage({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error,
            header: `Info`,
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            tryAgain: function () {
              cartView();
            },
            content: error?.response?.data?.error?.error ? error.response.data.error.error : "Something went wrong!",
          });
        }
      });
  };

  const removeFromCart = (item, deleteCartIndex) => {
    setShowDeleteModal({show: false, id: item.medicineId});
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    const params = {};
    const body = {
      androidMap: {
        locale: "en",
        source: "pharmacyWeb",
        deviceId: null,
        appVersion: navigator.userAgent,
        deviceName: null,
        screen_name: "cart_screen",
        appVersionCode: "156",
      },
      medicineId: item.medicineId,
      retailerCode: data.retailerCode,
      retailerId: data.id,
    };
    Cart_Actions("REMOVE_FROM_CART", params, body)
      .then((res) => {
        setCartData(res.data.data);
        if (deleteCartIndex >= 0) {
          if (selectedItems.length - 1 === deleteCartIndex) {
            setDeleteItemProgress(false);
            cartView();
            get_credit_status();
          }
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            removeFromCart(item);
          });
        } else if (error?.response?.status === 502 || error?.response?.status === 503 || error?.response?.status === 504) {
          setShowMaintenance({status: true, apiData: error?.response});
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              removeFromCart(item);
            },
          });
        } else if (error?.response?.status === 400) {
          setOrderMessage({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error,
            header: `Info`,
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error ? error.response.data.error.error : "Something went wrong!",
            tryAgain: function () {
              removeFromCart(item);
            },
          });
        }
      });
  };

  const notifyOutOfStock = (item) => {
    setShowSpinner({show: true, id: item.medicineId});
    setShowDeleteModal({show: false, id: item.medicineId});
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    const params = {
      retailerId: data.id,
      medicineId: item.medicineId,
    };
    const body = {};
    Cart_Actions("NOTIFY_OUT_OF_STOCK", params, body)
      .then((res) => {
        setShowSpinner({show: false, id: 0});
      })
      .catch((error) => {
        setShowSpinner({show: false, id: 0});
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            notifyOutOfStock(item);
          });
        } else if (error?.response?.status === 502 || error?.response?.status === 503 || error?.response?.status === 504) {
          setShowMaintenance({status: true, apiData: error?.response});
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              notifyOutOfStock(item);
            },
          });
        } else if (error.response.status === 400) {
          setOrderMessage({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error,
            header: `Info`,
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error ? error.response.data.error.error : "Something went wrong!",
            tryAgain: function () {
              notifyOutOfStock(item);
            },
          });
        }
      });
  };

  const sortShipments = (products) => {
    let groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    let groupByAvailableSlots = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[JSON.stringify(x[key])] = rv[JSON.stringify(x[key])] || []).push(x);
        return rv;
      }, {});
    };

    let groupBySlots = groupBy(products, "selectedSlotName");
    let arr = Object.keys(groupBySlots).filter((slot) => slot !== "null");
    setOutOfStock(groupBySlots[null]);
    let shipmentList = arr.map((key) => {
      let groupByAvailable = groupByAvailableSlots(groupBySlots[key], "availableSlots");
      return groupByAvailable;
    });
    setShipmentsShimmer(false);
    setShipments(shipmentList);
    getShipmentsValuesData(shipmentList);
  };

  const getShipmentsValuesData = (list) => {
    let arr = [];
    list.forEach((shipment) => {
      let invoiceVal = 0;
      let length = 0;
      Object.values(shipment).forEach((subShip) => {
        subShip.forEach((items) => {
          length = length + 1;
          invoiceVal = invoiceVal + items.actualPrice;
        });
      });
      arr.push({invoiceVal, totalItems: length});
    });
    setShipmentsValueData(arr);
  };

  let slotChange = (items, value) => {
    let selectedValue = value;
    let cartItems = cartDataIn.cartMedicineDetailResponseList;
    items.forEach((item) => {
      let index = cartItems.indexOf(item);
      cartItems[index].selectedSlotName = selectedValue;
    });
    let cartData = cartDataIn;
    cartData.cartMedicineDetailResponseList = cartItems;
    setCartDataIn(cartData);
    cartItems && cartItems.length > 0 && sortShipments(cartItems);
  };

  const scrollToProduct = (id) => {
    console.log(id);
    scroller.scrollTo(id, {
      smooth: true,
      offset: -150,
    });

    Events.scrollEvent.register("end", function (to, element) {
      setTimeout(() => {
        setAnimateCard({show: true, id: id});
      }, 500);
      setTimeout(() => {
        setAnimateCard({show: false, id: id});
      }, 1500);
    });
  };

  const paymentChange = (val) => {
    setPayment(val);
    if (val === "CREDIT") {
      if (creditData?.error.length) {
        setPlaceOrderError({show: true, data: creditData?.error[0]});
      }
    }
  };

  const selectReferral = (e) => {
    let obj = JSON.parse(JSON.stringify(selectedCredit));
    obj.referral.selected = e ? e.selected : !selectedCredit.referral.selected;
    if (e?.e.selected || !selectedCredit.referral.selected === true) {
      if (selectedCredit.subTotal > 0) {
        obj.referral.amount = cartDataIn?.creditAmount > selectedCredit.subTotal ? selectedCredit.subTotal : cartDataIn.creditAmount;
        obj.subTotal = obj.subTotal - obj.referral.amount;
      }
    } else {
      obj.subTotal = obj.subTotal + obj.referral.amount;
    }

    setSelectedCredit(obj);
  };

  const selectSaveoCredit = (e) => {
    let obj = JSON.parse(JSON.stringify(selectedCredit));
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "saveo_credit_tap",
      is_checked: e ? e.selected : !selectedCredit.saveoCredit.selected,
    });

    obj.saveoCredit.selected = e ? e.selected : !selectedCredit.saveoCredit.selected;
    if (e?.e.selected || !selectedCredit.saveoCredit.selected === true) {
      if (selectedCredit.subTotal > 0) {
        obj.saveoCredit.amount = cartDataIn?.saveoCreditAmount > selectedCredit.subTotal ? selectedCredit.subTotal : cartDataIn.saveoCreditAmount;

        obj.subTotal = obj.subTotal - obj.saveoCredit.amount;
      }
    } else {
      obj.subTotal = obj.subTotal + obj.saveoCredit.amount;
    }
    console.log(obj);
    setSelectedCredit(obj);
  };

  const placeOrder = async () => {
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "place_order_tap",
    });

    //check credit status
    getCreditUserOnboardingPopUp();

    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    if (!selectedPayment) {
      setSelectPaymentError(true);
      setTimeout(() => {
        setSelectPaymentError(false);
      }, 2000);
    } else {
      if (userData?.enabled) {
        setPlaceOrderSpinner(true);
        const params = {};
        let cartList = [];
        cartDataIn.cartMedicineDetailResponseList.forEach((item) => {
          let obj = {
            dealType: "string",
            medicineId: "string",
            quantity: 0,
            selectedSlotDate: "string",
            selectedSlotName: "string",
          };
          obj.dealType = item.dealType;
          obj.medicineId = item.medicineId;
          obj.quantity = item.quantity;
          obj.selectedSlotDate = "";
          obj.selectedSlotName = item.selectedSlotName;
          cartList.push(obj);
        });
        const body = {
          cartRequestList: cartList,
          detectWithCreditAmount: selectedCredit.referral.selected ? true : false,
          orderOnBehalf: false,
          priority: true,
          referralCode: null,
          retailerId: data.id,
          saveoCreditAmountFlag: selectedCredit.saveoCredit.selected,
          size: cartDataIn.size,
          slotName: cartDataIn.cartMedicineDetailResponseList[0].selectedSlotName,
        };
        Cart_Actions("PLACE_ORDER", params, body)
          .then((res) => {
            Common_Event_Hit("DYNAMIC_EVENT", {
              eventName: "order_placed",
            });
            setPlaceOrderSpinner(false);
            setSuccessDialog({
              show: true,
              content: "Yay Order Placed Successfully",
              creditLoanTapMessage: cartDataIn?.creditDiscoutingMessage,
            });
            cartView();
          })
          .catch((error) => {
            setPlaceOrderSpinner(false);
            if (error?.response?.status === 401) {
              refreshToken().then((res) => {
                placeOrder();
              });
            } else if (error?.response?.status === 502 || error?.response?.status === 503 || error?.response?.status === 504) {
              // setShowMaintenance({ status: true, apiData: error?.response });
              let time = new Date().getTime();
              localStorage.setItem("orderInProgressTime", time);
              setShowOrderInProgress(true);
            } else if (error?.response?.status === 500) {
              setErrorModal({
                show: true,
                apiData: error?.response,
                content: "Oops something went wrong !",
                tryAgain: function () {
                  placeOrder();
                },
              });
            } else {
              setErrorModal({
                show: true,
                apiData: error?.response,
                content: error?.response?.data.error?.error ? error.response.data.error.error : "Something went wrong!",
                tryAgain: function () {
                  placeOrder();
                },
              });
            }
          });
      } else {
        //the user may be enabled while trying to place order
        //hit the checkAuth api and refresh the localStorage userData
        //check the updated data -> if enabled ? retry the place order api : throw him to the onboarding route

        //hit the check auth api to refresh the data
        let body = {
          mobileNumber: userData?.mobileNo,
        };
        //update the spinner state
        setPlaceOrderSpinner(true);
        try {
          const res = await checkAuth(body);
          // update userData in local storage
          localStorage.setItem("userData", JSON.stringify(res.data.data.user));

          //update the spinner state
          setPlaceOrderSpinner(false);

          if (res?.data?.data?.user?.enabled) placeOrder();
          else navigate("/onboarding");
        } catch (error) {
          setPlaceOrderSpinner(false);
          if (error?.response?.status === 401) {
            refreshToken().then((res) => {
              placeOrder();
            });
          } else if (error?.response?.status === 502 || error?.response?.status === 503 || error?.response?.status === 504) {
            // setShowMaintenance({ status: true, apiData: error?.response });
            let time = new Date().getTime();
            localStorage.setItem("orderInProgressTime", time);
            setShowOrderInProgress(true);
          } else if (error?.response?.status === 500) {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: "Oops something went wrong !",
              tryAgain: function () {
                placeOrder();
              },
            });
          } else {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: error?.response?.data.error?.error ? error.response.data.error.error : "Something went wrong!",
              tryAgain: function () {
                placeOrder();
              },
            });
          }
        }
      }
    }
  };

  //credit onboard popup API call
  const getCreditUserOnboardingPopUp = () => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    let params = {
      retailerId: data?.id,
      source: "cart_screen",
    };
    creditOnboardingPopup(params, {})
      .then((res) => {
        setAgreementPopUpData(res?.data?.data);
        if (res?.data?.data?.showPopUp) setAgreementPopUp({show: true});
      })
      .catch((error) => {
        console.log(error.response);
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            getCreditUserOnboardingPopUp();
          });
        } else if (error?.response?.status === 502 || error?.response?.status === 503 || error?.response?.status === 504) {
          setShowMaintenance(true);
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            content: "Oops something went wrong!",
            tryAgain: function () {
              getCreditUserOnboardingPopUp();
            },
          });
        } else {
          setErrorModal({
            show: true,
            content: error?.response?.data?.error?.error ? error.response.data.error.error : "Something went wrong!",
            tryAgain: function () {
              getCreditUserOnboardingPopUp();
            },
          });
        }
      });
  };

  //api to update the change in delivery slot per each shipment
  const changeDeliverySlot = async (data, updatedDeliverySlot) => {
    let medicineIds = data.map((item, _) => item?.id);
    //get the respective slot timing from the data object
    let slotTiming = "";
    data[0]?.availableSlots.forEach((slot, index) => {
      if (slot?.slotName === updatedDeliverySlot) slotTiming = slot?.slotTiming;
    });

    let body = {
      cartMedicineDetailIds: medicineIds,
      selectedSlot: updatedDeliverySlot,
      slotTiming: slotTiming,
    };
    //make the api call
    try {
      const res = await Cart_Actions("CHANGE_DELIVERY_SLOT", {}, body);
      console.log("change delivery ", res);
      // throw 'error in change delivery slot api';
      //only call the slot change when changeDeliverySlot resolves
      slotChange(data, updatedDeliverySlot);
    } catch (error) {
      console.log(error.response);
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          changeDeliverySlot(data, updatedDeliverySlot);
        });
      } else if (error?.response?.status === 502 || error?.response?.status === 503 || error?.response?.status === 504) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            changeDeliverySlot(data, updatedDeliverySlot);
          },
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error ? error.response.data.error.error : "Something went wrong!",
          tryAgain: function () {
            changeDeliverySlot(data, updatedDeliverySlot);
          },
        });
      }
    }
  };

  function get_credit_status() {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    if (data) {
      const params = {
        retailerId: data.retailerCode,
      };
      let api = common_product_widget("credit/user/getCreditStatus", params);
      api
        .then((res) => {
          setCreditData(res.data.data);
        })
        .catch((error) => {
          if (error.response.status === 502 || error.response.status === 503 || error.response.status === 504) {
            setShowMaintenance({status: true, apiData: error?.response});
          } else if (error.response.status === 500) {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: "Oops something went wrong !",
            });
          } else {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: error?.response?.data?.error?.error ? error.response.data.error.error : "Something went wrong!",
            });
          }
        });
    } else {
      navigate("/home");
    }
  }
  useEffect(() => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    localStorage.setItem("currentScreen", "cart_screen");
    localStorage.removeItem("currentScreenBannerData");

    if (data) {
      let onBehalfCode = localStorage.getItem("onBehalfRetCode");
      if (onBehalfCode?.length) {
        setOnBehalfStatus(true);
      }
      setUserData(data);
      let getPageData = () => {
        const params = {
          retailerId: data.id,
          screenName: "cart_screen",
        };
        setScreenShimmer(true);
        getScreen("HOME_SCREEN", params, {})
          .then((res) => {
            setWidgetsData(res.data.data);

            setScreenShimmer(false);
            res.data.data.forEach((item) => {
              let params;
              if (item.widgetName === "cart_widget") {
                setCartShimmer(true);
                cartView();
              } else if (item.widgetName === "product_list_widget" && item.data.pageId === "trendingCart") {
                params = {
                  size: 6,
                  screenName: "cartPage",
                };
                setRecProductShimmer(true);
              } else if (item.widgetName === "product_list_widget" && item.data.pageId === "orderAgainCart") {
                params = {
                  size: 6,
                };
                setOrderAgainShimmer(true);
              }
              if (item.widgetName !== "cart_widget") {
                let api = common_product_widget(item.data.apiEndPoint, params);

                api.then((res) => {
                  if (item.data.pageId === "trendingCart") {
                    setRecProductShimmer(false);
                    setRecProducts(res.data.data);
                  } else if (item.data.pageId === "orderAgainCart") {
                    setOrderAgainShimmer(false);
                    setOrderAgain(res.data.data);
                  }
                }).catch((error)=>{
                    
                })
              }
            });
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              refreshToken().then((res) => {
                getPageData();
              });
            } else if (error?.response?.status === 502 || error?.response?.status === 503 || error?.response?.status === 504) {
              setShowMaintenance({status: true, apiData: error?.response});
            } else if (error?.response?.status === 500) {
              setErrorModal({
                show: true,
                apiData: error?.response,
                content: "Oops something went wrong !",
                tryAgain: function () {
                  getPageData();
                },
              });
            } else {
              setErrorModal({
                show: true,
                apiData: error?.response,
                content: error?.response?.data?.error?.error ? error.response.data.error.error : "Something went wrong!",
                tryAgain: function () {
                  getPageData();
                },
              });
            }
          });
      };

      getPageData();
    } else {
      navigate("/home");
    }
  }, []);

  useEffect(() => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    if (data) {
      //navigate to wholesale if businessType = wholesale
      if (data.businessType[0] !== "PHARMACY") navigate("/wholesale/home");

      const params = {
        retailerId: data.retailerCode,
      };
      let api = common_product_widget("credit/user/getCreditStatus", params);
      api
        .then((res) => {
          setCreditData(res.data.data);
        })
        .catch((error) => {
          if (error.response.status === 502 || error.response.status === 503 || error.response.status === 504) {
            setShowMaintenance({status: true, apiData: error?.response});
          } else if (error.response.status === 500) {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: "Oops something went wrong !",
            });
          } else {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: error?.response?.data?.error?.error ? error.response.data.error.error : "Something went wrong!",
            });
          }
        });
    } else {
      navigate("/home");
    }
  }, []);

  useEffect(() => {
    if (cartData) {
      setCartDataIn(cartData);
      let obj = JSON.parse(JSON.stringify(selectedCredit));
      obj.subTotal = cartData.subTotal;
      setSelectedCredit(obj);
      let products = cartData.cartMedicineDetailResponseList;
      products && products.length > 0 && sortShipments(products);
      console.log("updated");
    }
  }, [cartData]);

  useEffect(() => {
    if (localStorage.getItem("orderInProgressTime")) {
      setShowOrderInProgress(true);
    }
  }, [localStorage.getItem("orderInProgressTime")]);

  let selectAllCart = (e) => {
    if (e.target.checked) {
      setSelectAll(true);
      let itemsList = cartDataIn.cartMedicineDetailResponseList.map((item) => {
        item.selected = true;
        return item;
      });
      setSelectedItems(itemsList);
      sortShipments(itemsList);
    } else {
      setSelectAll(false);
      setSelectedItems([]);
      let itemsList = cartDataIn.cartMedicineDetailResponseList.map((item) => {
        item.selected = false;
        return item;
      });
      sortShipments(itemsList);
    }
  };

  const selectCartItem = (e, item) => {
    if (e.target.checked) {
      let itemsList = [...selectedItems];
      itemsList.push(item);

      setSelectedItems(itemsList);

      let items = cartDataIn.cartMedicineDetailResponseList.map((x) => {
        if (item.medicineId === x.medicineId) {
          x.selected = true;
        }
        return x;
      });
      sortShipments(items);
      console.log(itemsList);
    } else {
      setSelectAll(false);
      let itemsList = [...selectedItems];
      for (let i = 0; i < selectedItems.length; i++) {
        if (selectedItems[i].medicineId === item.medicineId) {
          itemsList.splice(i, 1);
          break;
        }
      }
      setSelectedItems(itemsList);
      let items = cartDataIn.cartMedicineDetailResponseList.map((x) => {
        if (item.medicineId === x.medicineId) {
          x.selected = false;
        }
        return x;
      });
      sortShipments(items);
      console.log(itemsList);
    }
  };

  const deleteCart = () => {
    setShowDeleteCart(false);
    setDeleteItemProgress(true);
    selectedItems.forEach((item, i) => {
      removeFromCart(item, i);
    });
  };

  const dwnldCSV = () => {
    const fields = ["medicineName", "quantity", "medicineId", "manufacturer", "packaging", "mrp", "ptr", "cashDiscount", "gst", "actualPrice"];
    const header = ["medicineName", "Qty", "MedicineId", "manufacturer", "packaging", "mrp", "ptr", "CD%", "GST%", "Total Price"];
    downloadErrorFile(cartDataIn.cartMedicineDetailResponseList, header, fields);
  };

  const downloadErrorFile = (data, header, fields) => {
    // specify how you want to handle null values here
    const replacer = (key, value) => (value === null ? "" : value);

    const csv = data.map((row) => fields.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(","));

    // add headers
    csv.unshift(header.join(","));
    const csvArray = csv.join("\r\n");

    // generate file
    const a = document.createElement("a");
    const blob = new Blob([csvArray], {type: "text/csv"});
    const url = window.URL.createObjectURL(blob);

    // download file
    a.href = url;
    a.download = `${cartDataIn.retailerCode}_CART_CSV`;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  };

  const getCreditBlock = () => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    let onBehalfCode = localStorage.getItem("onBehalfRetCode");
    const params = {
      retailerCode: onBehalfCode?.length ? onBehalfCode : data.retailerCode,
    };
    Cart_Actions("CREDIT_BLOCK", params)
      .then(async (res) => {
        setCreditBlock(res?.data?.data)
      })
      .catch((error) => {
        setErrorHandler({
          show: true,
          screen: "cart_screen",
          errorList: [{error: error, code: ""}],
          fn: function () {
            getCreditBlock();
          },
        });
      });
  };

  useEffect(() => {
    getCreditBlock();
  }, []);

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({show: false, content: ""});
        }}
        onHide={() => {
          setErrorModal({show: false, content: ""});
        }}
      />
      <OrderMessageModal
        show={orderMessage.show}
        content={orderMessage.content}
        header={orderMessage.header}
        onHide={() => {
          setOrderMessage({show: false, content: "", header: ""});
        }}
      />
      <AcceptCreditAgreementModal
        show={agreementPopUp.show}
        creditUserData={agreementPopUpData}
        onHide={() => {
          setAgreementPopUp({show: false});
        }}
      />
      <OrderSuccessModal show={showSuccessDialog.show} content={showSuccessDialog.content} creditMessage={showSuccessDialog?.creditLoanTapMessage} onHide={() => setSuccessDialog({show: false, content: ""})} />
      <OrderInProgress
        show={showOrderInProgress}
        onHide={() => {
          if (showOrderInProgress) {
            cartView();
            get_credit_status();
          }
          setShowOrderInProgress(false);
        }}
      />
      <DeletingCartItemsModal show={showDeleteItemsProgress} />
      <DeleteProductModal show={showDeleteCart} itemName="selected items" action="clear_cart" download={dwnldCSV} removeFromCart={() => deleteCart()} onHide={() => setShowDeleteCart(false)} />
      {screenShimmer ? (
        <div className="cart_parent">
          {[...Array(4)].map((_, index) => {
            return <div className="skeletal_div animateShimmer" key={index}></div>;
          })}
        </div>
      ) : (
        widgetsData &&
        widgetsData?.length > 0 &&
        widgetsData.map((widget, i) => {
          if (widget.widgetName === "cart_widget") {
            return cartShimmer ? (
              <Container fluid className="cart_parent" style={{backgroundColor: "#fff"}}>
                <div className="skeletal_div animateShimmer"></div>
              </Container>
            ) : (
              <Container fluid className="cart_parent">
                <Row>
                  <Col className="cart_header display_flex">
                    <HiOutlineArrowNarrowLeft className="cursor_pointer goBack" onClick={() => navigate(-1)} /> &nbsp;&nbsp;&nbsp;{" "}
                    <div className="font_18 color_primary font_ubuntu font_weight_md " style={{marginTop: "-5px"}}>
                      Cart
                    </div>
                    <br></br>
                    <br></br>
                  </Col>
                </Row>
                {cartDataIn && cartDataIn.cartMedicineDetailResponseList?.length > 0 ? (
                  <Row clasName="">
                    <Col lg={8} md={8} className="cart_items_widget">
                      <div>
                        {giftData?.length > 0 && (
                          <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                              <Accordion.Header className="gift_header">
                                <AiFillGift /> &nbsp; Gift Items
                              </Accordion.Header>

                              <Accordion.Body>
                                {giftData?.map((gifts) => {
                                  return (
                                    <div key={gifts.campaignId}>
                                      <div className="gift_banner">
                                        <img src={gifts.campaignImageUrl} />
                                      </div>

                                      {gifts.giftItemList.map((gift) => {
                                        return <p className="gift_list">{gift.giftDescription}</p>;
                                      })}
                                      <hr style={{color: "#C5C4C4"}} />
                                    </div>
                                  );
                                })}
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        )}

                        <br />

                        {cartDataIn && cartDataIn.message && Object.keys(cartDataIn.message).length > 0 && (
                          <Accordion defaultActiveKey="0">
                            <Accordion.Item
                              eventKey="0"
                              onClick={() => {
                                Common_Event_Hit("DYNAMIC_EVENT", {
                                  eventName: "updated_products_tap",
                                });
                              }}>
                              <Accordion.Header className="updated_products">
                                <AiOutlineInfoCircle /> &nbsp; {cartDataIn.message.noOfMedicineUpdated ? cartDataIn.message.noOfMedicineUpdated : cartDataIn.message.noOfOutOfStock ? cartDataIn.message.noOfOutOfStock : ""}
                              </Accordion.Header>
                              <Accordion.Body>
                                {cartDataIn.cartMedicineDetailResponseList.map((item, i) => {
                                  if (item.oldCashDiscount !== null || item.oldPtr !== null || item.oldMrp !== null || item.oldScheme !== null) {
                                    return (
                                      <>
                                        <DeleteProductModal
                                          show={showDeleteModal.show && showDeleteModal.id === item.medicineId}
                                          itemName={item.medicineName}
                                          removeFromCart={() => removeFromCart(item)}
                                          onHide={() =>
                                            setShowDeleteModal({
                                              show: false,
                                              id: item.medicineId,
                                            })
                                          }
                                        />

                                        <div className="updatedProductCard" key={i}>
                                          <div>
                                            <div className="color_grey font_weight_md updatedProductName">{item.medicineName}</div>
                                            <div className="display_flex">
                                              {item.oldCashDiscount !== null && item.oldCashDiscount !== item.cashDiscount && (
                                                <div className="updated_cd">
                                                  <b>CD</b> : <span className="color_grey text_line_through font_weight_md">{item.oldCashDiscount} %</span>
                                                  <b>&nbsp; {item.cashDiscount} %</b>
                                                </div>
                                              )}

                                              {item.oldPtr !== null && item.oldPtr !== item.ptr && (
                                                <div className="updated_cd">
                                                  &nbsp; <span className="color_grey"> | </span> &nbsp;
                                                  <b>Ptr</b> : <span className="color_grey text_line_through font_weight_md">{item.oldPtr}</span>
                                                  <b>&nbsp; {item.ptr}</b>
                                                </div>
                                              )}

                                              {item.oldMrp !== null && item.oldMrp !== item.mrp && (
                                                <div className="updated_cd">
                                                  &nbsp; <span className="color_grey"> | </span> &nbsp;
                                                  <b>MRP</b> : <span className="color_grey text_line_through font_weight_md">{item.oldMrp}</span>
                                                  <b>&nbsp; {item.mrp}</b>
                                                </div>
                                              )}

                                              {item.oldScheme !== null && item.oldScheme !== item.scheme && (
                                                <div className="updated_cd">
                                                  &nbsp; <span className="color_grey"> | </span> &nbsp;
                                                  <b>Scheme</b> : <span className="color_grey text_line_through font_weight_md">{item.oldScheme}</span>
                                                  <b>&nbsp; {item?.scheme?.join(",")}</b>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                          <div className="dispaly_flex margin_10px">
                                            <button
                                              className="removeFromCartBtn"
                                              onClick={() => {
                                                setShowDeleteModal({
                                                  show: true,
                                                  id: item.medicineId,
                                                });
                                                Common_Event_Hit("DYNAMIC_EVENT", {
                                                  eventName: "on_remove_tap",
                                                  medicineId: item.medicineId,
                                                  medicineName: item.medicineName,
                                                });
                                              }}>
                                              {" "}
                                              REMOVE
                                            </button>
                                            &nbsp;
                                            <button
                                              onClick={() => {
                                                scrollToProduct(item.medicineId);
                                                Common_Event_Hit("DYNAMIC_EVENT", {
                                                  eventName: "on_edit_tap",
                                                  medicineId: item.medicineId,
                                                  medicineName: item.medicineName,
                                                });
                                              }}
                                              className="editFromCartBtn color_secondary">
                                              {" "}
                                              EDIT
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  }
                                })}
                                {outOfStock && outOfStock.length > 0 && (
                                  <Accordion className="brdr_none">
                                    <Accordion.Item
                                      eventKey="0"
                                      className="brdr_none"
                                      onClick={() => {
                                        Common_Event_Hit("DYNAMIC_EVENT", {
                                          eventName: "out_of_stock_tap",
                                        });
                                      }}>
                                      <Accordion.Header className="out_of_stock font_ubuntu brdr_none">{outOfStock.length} out of stock products removed from cart</Accordion.Header>
                                      <Accordion.Body>
                                        {outOfStock.map((item, i) => {
                                          if (item?.reason === "out_of_stock") {
                                            return (
                                              <div className="display_flex space_btw updatedProductCard" key={i}>
                                                <div className="color_grey updatedProductName">{item.medicineName}</div>
                                                <button
                                                  className="editFromCartBtn color_secondary"
                                                  onClick={() => {
                                                    Common_Event_Hit("DYNAMIC_EVENT", {
                                                      eventName: "on_notify_me_tap",
                                                      medicineId: item.medicineId,
                                                      medicineName: item.medicineName,
                                                    });
                                                    notifyOutOfStock(item);
                                                  }}>
                                                  {" "}
                                                  {showSpinner.show && showSpinner.id === item.medicineId ? (
                                                    <Spinner
                                                      animation="border"
                                                      className="color_secondary spinner"
                                                      size="sm"
                                                      style={{
                                                        marginLeft: "-2px",
                                                      }}
                                                    />
                                                  ) : (
                                                    "NOTIFY ME"
                                                  )}
                                                </button>
                                              </div>
                                            );
                                          }
                                        })}
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                )}
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        )}

                        {shipmentsShimmer ? (
                          <div className="cart_items">
                            <div className="cart_card animateShimmer" style={{height: "200px"}}></div>
                          </div>
                        ) : (
                          <>
                            {shipments &&
                              shipments.map((shipment, i) => {
                                return (
                                  <>
                                    <div className="color_dark_green padding_10 display_flex space_btw">
                                      <h5>
                                        Shipment {i + 1} • {shipmentsValueData && shipmentsValueData.length > 0 && shipmentsValueData[i]?.totalItems} Items in Cart
                                      </h5>
                                      {i === 0 && onBehalfStatus && (
                                        <div className="clear_cart_sec">
                                          <div className="dwnld_cart_csv" id="dwnld_cart_csv" onClick={dwnldCSV}>
                                            Download CSV
                                          </div>
                                          <div className="clear_cart_btn">
                                            <input type="checkbox" className="cart_check" checked={selectAll} id="cart_select_all" onClick={selectAllCart} /> Select All{" "}
                                            {selectedItems?.length ? (
                                              <MdDelete
                                                className="delete_all"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setShowDeleteCart(true);
                                                }}
                                              />
                                            ) : selectAll ? (
                                              <MdDelete
                                                className="delete_all"
                                                id="cart_delete_all"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setShowDeleteCart(true);
                                                }}
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          {/* <p className="clear_selcted_btn">
                                            Clear Selected Items
                                          </p> */}
                                        </div>
                                      )}
                                    </div>
                                    <div className="subShip" key={i}>
                                      {Object.values(shipment).map((subShip, ind) => {
                                        return (
                                          subShip && (
                                            <div className="cart_items" key={ind}>
                                              <div className="display_flex ">
                                                {ind === 0 && (
                                                  <>
                                                    <div className="invoice_val">
                                                      <p className="font_14 color_grey font_weight_md">Invoice {i + 1} : </p>
                                                      <p className="font_16 font_weight_md">₹ {shipmentsValueData && shipmentsValueData.length > 0 && shipmentsValueData[i]?.invoiceVal.toFixed(2)}</p>
                                                    </div>
                                                  </>
                                                )}

                                                <div>
                                                  <p className="font_12 color_grey font_weight_md">Delivery Slot : </p>
                                                  <div className="radio_div">
                                                    {subShip[0].availableSlots.map((slot, i) => {
                                                      return (
                                                        <div>
                                                          <input type="radio" className="radioInput" id={slot.slotName + i} value={slot.slotName} checked={slot.slotName === subShip[0].selectedSlotName} onChange={(e) => changeDeliverySlot(subShip, e.target.value)} name={subShip[0].selectedSlotName + ind} />
                                                          <label for={slot.slotName + i} className="font_ubuntu font_14 font_weight_md label">
                                                            {slot.slotTiming}
                                                          </label>
                                                        </div>
                                                      );
                                                    })}{" "}
                                                  </div>
                                                </div>
                                              </div>
                                              {subShip.map((item, itemIndex) => {
                                                return (
                                                  <Element name={item.medicineId} className="cart_card_parent">
                                                    {onBehalfStatus && (
                                                      <input
                                                        type="checkbox"
                                                        className="cart_check_lg"
                                                        checked={item?.selected}
                                                        onClick={(e) => {
                                                          selectCartItem(e, item);
                                                        }}
                                                      />
                                                    )}
                                                    <CartCard item={item} key={itemIndex} animateCard={animateCard} genericMessage={genericMessage} genericStatus={genericsStatus.includes(item.medicineId)} id={item.medicineId} />
                                                  </Element>
                                                );
                                              })}
                                            </div>
                                          )
                                        );
                                      })}
                                    </div>
                                  </>
                                );
                              })}

                            {outOfStock &&
                              outOfStock.length &&
                              outOfStock.map((item, i) => {
                                if (item?.reason !== "out_of_stock")
                                  return (
                                    <Element name={item.medicineId}>
                                      <CartCard item={item} key={i} animateCard={animateCard} id={item.medicineId} />
                                    </Element>
                                  );
                              })}
                          </>
                        )}
                      </div>
                    </Col>

                    <Col className="cart_total_widget">
                      <div
                        className="font_ubuntu color_grey padding_10"
                        style={{
                          background: "white",
                          borderRadius: "4px",
                          border: "2px solid rgba(128, 128, 128, 0.158)",
                        }}>
                        <p className="font_weight_lg color_black">
                          <b>Price Detail</b>
                        </p>
                        <hr className="font_weight_lg color_black" />
                        {cartDataIn &&
                          cartDataIn.amountBreakUpObjectList.map((priceData, i) => {
                            return (
                              <>
                                <div
                                  key={i}
                                  className="display_flex space_btw font_weight_lg cursor_pointer"
                                  onClick={() =>
                                    priceData.subItems &&
                                    priceData.subItems.length > 0 &&
                                    setPriceDataDropdown({
                                      show: !priceDataDropdown.show,
                                      key: priceData.key,
                                    })
                                  }>
                                  <p>
                                    {priceData.key} {priceData.subItems && priceData.subItems.length > 0 && !priceDataDropdown.show ? <BiChevronDown /> : priceData.subItems && priceData.subItems.length > 0 && priceDataDropdown.show && priceDataDropdown.key === priceData.key ? <BiChevronUp /> : priceData.subItems && priceData.subItems.length > 0 && <BiChevronDown />}
                                  </p>{" "}
                                  <p>₹ {priceData.value.toFixed(2)}</p>
                                </div>
                                {priceData.subItems && priceDataDropdown.show && priceDataDropdown.key === priceData.key && (
                                  <>
                                    {priceData.subItems.map((subItem, i) => {
                                      return (
                                        <div key={i} className={subItem.highlight ? "highlight_item display_flex font_12 space_btw font_weight_lg" : "display_flex font_12 space_btw font_weight_lg"}>
                                          <p> &nbsp;&nbsp;{subItem.key} </p>
                                          <p>₹ {subItem.value}&nbsp;&nbsp;</p>
                                        </div>
                                      );
                                    })}
                                  </>
                                )}
                              </>
                            );
                          })}
                        {selectedCredit?.referral.selected && (
                          <div className="display_flex space_btw color_grey font_weight_lg">
                            <p className="font_12">Referral Credits </p> <p>- ₹ {cartDataIn?.creditAmount > cartDataIn.subTotal ? cartDataIn.totalWithGst : cartDataIn.creditAmount}</p>
                          </div>
                        )}
                        {selectedCredit?.saveoCredit.selected && (
                          <div className="display_flex space_btw color_grey font_weight_lg">
                            <p className="font_12">Saveo Credits </p> <p>- ₹ {cartDataIn?.saveoCreditAmount > cartDataIn.subTotal ? cartDataIn.subTotal : cartDataIn.saveoCreditAmount}</p>
                          </div>
                        )}

                        <hr className="font_weight_lg color_black" />
                        <div className="display_flex space_btw color_black font_weight_lg">
                          <p> Total</p> <p>₹ {selectedCredit?.subTotal.toFixed(2)}</p>
                        </div>
                      </div>

                      <div className="bg_primary  subTotal_note">
                        {cartDataIn?.creditAmount ? (
                          <>
                            <div className="display_flex space_btw credit_select_div cursor_pointer" onClick={() => selectReferral(null)}>
                              <div className="display_flex font_14">
                                <Form.Check id="saveo_referral_amount_check" onChange={(e) => selectReferral(e)} checked={selectedCredit.referral.selected} className="return_item_select cursor_pointer mb-3" />
                                &nbsp; <span style={{marginTop: "4px"}}>USE REFERRAL AMOUNT</span>
                              </div>
                              <div className="color_secondary font_16">₹ {cartDataIn?.creditAmount}</div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {cartDataIn?.saveoCreditAmount ? (
                          <>
                            <div className="display_flex space_btw credit_select_div cursor_pointer" onClick={() => selectSaveoCredit(null)}>
                              <div className="display_flex font_14">
                                <Form.Check onChange={(e) => selectSaveoCredit(e)} checked={selectedCredit.saveoCredit.selected} id="saveo_credits_check" className="return_item_select cursor_pointer mb-3" />
                                &nbsp; <span style={{marginTop: "4px"}}>USE SAVEO CREDITS</span>
                              </div>
                              <div className="color_secondary font_16">₹ {cartDataIn?.saveoCreditAmount}</div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        NOTE: {cartDataIn?.creditDiscoutingMessage?.length ? `${cartDataIn?.creditDiscoutingMessage}` : "Invoice value may differ from what is shown above due to different batches and schemes."}
                      </div>
                      <div className={selectPaymentError ? "errorAnime padding_10" : "padding_10"}>
                        <p>Select Payment</p>
                        {cartDataIn &&
                          cartDataIn.paymentOptions.map((option, i) => {
                            return (
                              <div className="payment_div display_flex" key={i}>
                                <input
                                  type="radio"
                                  className="radioInput padding_10"
                                  id={option.code}
                                  value={option.code}
                                  onChange={async (e) => {
                                    paymentChange(e.target.value);
                                    Common_Event_Hit("DYNAMIC_EVENT", {
                                      eventName: "payment_option_selected",
                                      payment_option: e.target.value,
                                    });
                                  }}
                                  name="payment"
                                />
                                {option.code === "CREDIT" ? (
                                  <label for={option.code} className="font_ubuntu font_12 font_weight_md label cursor_pointer display_flex space_btw">
                                    <span>
                                      {option.displayName}
                                      <br></br>
                                      <span className="font_10 color_primary">{creditData?.creditUserDetailsModel?.nbfcPartnerName}</span>
                                    </span>
                                    <span className="bg_primary_lt2 font_12 color_primary brdr_primary limit_card">
                                      LIMIT : ₹ {creditData?.creditUserDetailsModel?.availableLimit.toFixed(2)}
                                      {!creditData?.creditUserDetailsModel && "0"}
                                    </span>
                                  </label>
                                ) : (
                                  <label for={option.code} className="font_ubuntu font_14 font_weight_md label cursor_pointer">
                                    {option.displayName}
                                  </label>
                                )}
                              </div>
                            );
                          })}

                        {selectPaymentError && <p className="font_ubuntu font_12 color_warn">Please Select Payment Method !</p>}
                      </div>
                      <div>
                        <Button className="bg_secondary color_white payment_btn" onClick={placeOrder} id="placeOrder" disabled={showPlaceOrderSpinner || placeOrderError.show || creditBlock}>
                          {showPlaceOrderSpinner ? (
                            <Spinner
                              animation="border"
                              className="color_primary spinner"
                              size="sm"
                              style={{
                                margin: "0px auto",
                              }}
                            />
                          ) : (
                            <>
                              {placeOrderError.show ? (
                                <>
                                  <span className="color_primary">{placeOrderError.data.title.toUpperCase()}</span> <br></br>
                                  <span className="font_12">{placeOrderError.data.message}</span>{" "}
                                </>
                              ) : creditBlock?  <span className="color_primary">User Blocked</span> :(
                                "Place Order"
                              )}
                            </>
                          )}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <div className="emptyCart font_ubuntu">
                    <img src={EmptyCart} alt="empty cart" />
                    <h3>Empty Cart</h3>
                    <Button className="brdr_none bg_secondary" onClick={() => navigate("/search/")}>
                      Add Items
                    </Button>
                  </div>
                )}
              </Container>
            );
          } else if (widget.data.pageId === "trendingCart") {
            return recProductShimmer ? (
              <Container>
                <br></br>
                <p className="font_18 font_weight_md font_ubuntu">Recommended Products</p>
                <Slider shimmer={recProductShimmer} footerColor={"#F4F6F8"} id={"regularSchemePopular"} darkColor={"#4B4495"} lightColor={"#a49cf598"} />
              </Container>
            ) : (
              recProducts && recProducts.medicinesResponsesList.length > 0 && (
                <Container fluid style={{backgroundColor: widget.data.backgroundColor}}>
                  <br></br>
                  <p className="font_18 font_weight_md font_ubuntu" style={{color: widget.data.titleColor}}>
                    {widget.data.title}
                  </p>
                  <Slider widgetData={widget} ProductRes={recProducts} footerColor={"#F4F6F8"} id={"regularSchemePopular"} darkColor={"#4B4495"} lightColor={"#a49cf598"} />
                </Container>
              )
            );
          } else if (widget.data.pageId === "orderAgainCart") {
            return orderAgainShimmer ? (
              <Container>
                <br></br>
                <p className="font_18 font_weight_md font_ubuntu">Order Again</p>
                <Slider shimmer={orderAgainShimmer} footerColor={"#F4F6F8"} id={"recommendedProducts"} darkColor={"#4B4495"} lightColor={"#a49cf598"} />
              </Container>
            ) : (
              orderAgain && orderAgain.medicinesResponsesList.length > 0 && (
                <Container fluid style={{backgroundColor: widget.data.backgroundColor}}>
                  <br></br>
                  <p className="font_18 font_weight_md font_ubuntu" style={{color: widget.data.titleColor}}>
                    {widget.data.title}
                  </p>
                  <Slider widgetData={widget} ProductRes={orderAgain} footerColor={"#F4F6F8"} id={"orderAgain"} darkColor={"#4B4495"} lightColor={"#a49cf598"} />
                </Container>
              )
            );
          }
        })
      )}
      <br></br>
    </>
  );
};

export default Cart;
