import React, { Fragment, useContext, useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { useNavigate, useParams } from "react-router";
import { refreshToken } from "../../Actions/Auth";
import { getScreen } from "../../Actions/Screens";
import { common_product_widget } from "../../Actions/Widgets";
import { usersData, widgetContext } from "../../App";
import ErrorModal from "../Modals/ErrorModal";
import NoData from "../../Images/png/no_data.png";
import InfiniteScroll from "react-infinite-scroll-component";
import ProductCard2 from "../ProductCard/ProductCard2";

function HomeProductList() {
  const { widgetData, setWidgetData } = useContext(widgetContext);
  const { setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [shimmer, setShimmer] = useState(false);
  const { id: productsType } = useParams();
  const navigate = useNavigate();
  const [productsData, setProductsData] = useState([]);
  const [page, setPage] = useState(0);
  const [currentWidget, setCurrentWidget] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const [dataLength, setDataLength] = useState(0);

  //api to get widgetData from home screen
  const fetchHomeWidgets = async () => {
    let userData = localStorage.getItem("userData");
    userData = JSON.parse(userData);

    let params = {
      retailerId: userData?.id,
      screenName: "home_screen",
    };

    //update the shimmer state
    setShimmer(true);

    try {
      const res = await getScreen("HOME_SCREEN", params, {});
      // console.log(res?.data?.data);
      //update the widgetData context
      setWidgetData(res?.data?.data);
      //update the shimmer state
      setShimmer(false);
    } catch (error) {
      // console.log(error);
      if (error?.response?.status === 401) {
        refreshToken()
          .then((res) => {
            fetchHomeWidgets();
          })
          .catch((error) => {
            localStorage.removeItem("userData");
            localStorage.removeItem("userAuth");
            navigate("/login");
          });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance({ status: true, apiData: error?.response });
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          apiData: error?.response,
          content: "Oops something went wrong !",
          tryAgain: function () {
            fetchHomeWidgets();
          },
        });
      } else {
        setErrorModal({
          show: true,
          apiData: error?.response,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            fetchHomeWidgets();
          },
        });
      }
    }
  };

  //api to get productsData
  const getProductsData = async () => {
    //get the api endpoint from currentWidget
    let apiEndPoint = currentWidget?.apiEndPoint;
    let includedParams = new URLSearchParams(apiEndPoint?.split("?")[1]);
    // includedParams.delete("page");
    let params = {};
    for (var pair of includedParams.entries()) {
      if (pair[0] === "pair") continue;
      params[pair[0]] = pair[1];
    }
    params.page = page;
    if (productsType === "home_order_again") {
      params.size = 20 * (page + 1);
    }
    //update the shimmer state if page === 0
    if (page === 0) setShimmer(true);

    try {
      const res = await common_product_widget(apiEndPoint, params);
      // console.log(res?.data?.data);
      //update the shimmer state for page === 0
      if (page === 0) setShimmer(false);
      //update the productsData state
      let data =
        productsData && productsData.medicinesResponsesList
          ? productsData
          : { medicinesResponsesList: [] };
      if (res.data.data.medicinesResponsesList.length > 0) {
        if (productsType === "home_order_again") {
          data.medicinesResponsesList = res.data.data.medicinesResponsesList;
        } else {
          data.medicinesResponsesList.push(
            ...res.data.data.medicinesResponsesList
          );
        }

        setDataLength(data.medicinesResponsesList.length);
      }
      setProductsData(data);

      //fetchMoreData logic
      if (res.data.data.size > 0 && data.medicinesResponsesList.length < 15) {
        fetchMoreData();
      } else if (
        res.data.data.size > 0 &&
        res.data.data.medicinesResponsesList.length === 0
      ) {
        fetchMoreData();
      }

      if (res.data.data.size === 0) {
        setHasMore(false);
      }
    } catch (error) {
      //update shimmer state for page = 0 if any error
      if (page === 0) setShimmer(false);
      // console.log(error);
      //update the shimmer state
      //   setShimmer(false);
      if (error?.response?.status === 401) {
        refreshToken()
          .then((res) => {
            getProductsData();
          })
          .catch((error) => {
            localStorage.removeItem("userData");
            localStorage.removeItem("userAuth");
            navigate("/login");
          });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance({ status: true, apiData: error?.response });
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          apiData: error?.response,
          content: "Oops something went wrong !",
          tryAgain: function () {
            getProductsData();
          },
        });
      } else {
        setErrorModal({
          show: true,
          apiData: error?.response,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            getProductsData();
          },
        });
      }
    }
  };

  //update the page state
  //side effect handled in useEffect below
  let fetchMoreData = () => {
    setPage(+page + 1);
  };

  //initial
  useEffect(() => {
    //if widgetData is empty-> hit the fetchWidgets Api
    if (widgetData && Object.keys(widgetData).length === 0) {
      fetchHomeWidgets();
    } else if (
      widgetData &&
      Object.keys(widgetData).length > 0 &&
      productsType
    ) {
      // console.log({ productsType });
      //update the currentWidget state
      widgetData?.forEach((item, index) => {
        if (
          //   item?.widgetName === "product_list_widget" &&
          item?.data?.pageId === productsType
        ) {
          setCurrentWidget(item?.data);
        }
      });
      //get the respective products
      getProductsData();
    }
  }, [widgetData, productsType]);

  //hit the getProductsData when current widget changes
  useEffect(() => {
    //return if current widget is emptfy
    if (Object.keys(currentWidget).length === 0 && page === 0) return;
    getProductsData();
  }, [currentWidget, page]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // console.log({ productsData });
    if (Object.keys(productsData).length === 0) setShimmer(true);
    else setShimmer(false);
  }, [productsData]);

  return (
   
      <Fragment>
      <Container fluid className="productList_cont">
        <Container fluid className="productListHeader font_ubuntu">
          {" "}
          <HiOutlineArrowNarrowLeft
            className="cursor_pointer goBack"
            onClick={() => navigate(-1)}
          />
          &nbsp;&nbsp;&nbsp;<span>Home &nbsp;{">"}&nbsp;</span>{" "}
          {currentWidget && currentWidget?.title}
        </Container>

        {!shimmer &&
          productsData &&
          productsData?.medicinesResponsesList?.length > 0 && (
            <div
              id="scrollableDiv"
              className="productListDiv"
              style={{ paddingTop: "0rem" }}
            >
              <InfiniteScroll
                dataLength={dataLength}
                next={fetchMoreData}
                hasMore={hasMore}
                scrollableTarget="scrollableDiv"
                scrollThreshold="90%"
                className="infiniteScroll"
                loader={
                  <Spinner
                    animation="border"
                    className="color_secondary spinner"
                    size="lg"
                  />
                }
              >
                {productsData &&
                  productsData?.medicinesResponsesList?.map((med, index) => (
                    <ProductCard2
                      position={index}
                      screen={productsType}
                      key={index}
                      medData={med}
                    />
                  ))}
              </InfiniteScroll>
            </div>
          )}

        {!shimmer &&
          productsData &&
          productsData?.medicinesResponsesList?.length === 0 && (
            <div
              id="scrollableDiv"
              className="productList "
              style={{ paddingTop: "50px" }}
            >
              <div className="no_data_parent">
                <img src={NoData} alt="No Data" />
                <br /> <br />
                <p className="font_ubuntu font_16 font_weight_md">
                  No Products Found !
                </p>
              </div>
            </div>
          )}
        {shimmer && (
          <>
            {[...Array(5)].map((_, index) => {
              return (
                <div className="padding_5 productList">
                  <ProductCard2 shimmer={shimmer} />
                </div>
              );
            })}
          </>
        )}
      </Container>
      </Fragment>
  );
}

export default React.memo(HomeProductList);
