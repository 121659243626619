import React, { useContext, useEffect, useState } from "react";
import { Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { refreshToken } from "../../Actions/Auth";
import { category_widget, common_product_widget } from "../../Actions/Widgets";
import { usersData } from "../../App";
import ErrorModal from "../Modals/ErrorModal";
import { BsPiggyBank } from "react-icons/bs";
import { IoWalletOutline } from "react-icons/io5";
import { AiOutlineRight } from "react-icons/ai";
import Generic from "../../Images/svg/generic.svg";
import { Common_Event_Hit } from "../../Actions/UserEvents";
import { MY_ACCOUNT_IMAGES } from "../../Images/svg";
import { IoIosArrowForward } from "react-icons/io";
import { getUserLedgerBalanceDetails } from "../../Actions/Ledger";

const Store = () => {
  let navigate = useNavigate();
  const webEnabledWidgets = [
    "yourOrders",
    "profile",
    "yourReturns",
    "yourBounces",
    "downloadCsv",
    "applyCredit",
    "qna",
    "reportIssue",
    "yourExpiry",
    "agreement"
  ];
  const { setShowMaintenance, setErrorHandler, creditStatus } =
    useContext(usersData);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [homeShimmer, setHomeShimmer] = useState(false);
  const [ledger, setLedger] = useState({ loader: false, data: {} });
  const [categoryRes, setCategoryRes] = useState();
  const [userData, setUserData] = useState();

  const fetchAccounts = () => {
    
    setHomeShimmer(true);
    const obj = category_widget("home/fetchAccountSectionV2", userData?.id);

    obj
      .then((res) => {
        setHomeShimmer(false);
        setCategoryRes(res.data.data);
      })
      .catch((error) => {
        setHomeShimmer(false);
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            fetchAccounts();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              fetchAccounts();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              fetchAccounts();
            },
          });
        }
      });
  };

  const copyText = (text) => {
    navigator.clipboard.writeText(text);
  };

  const getOnBehalfRetData = (retCode) => {
    common_product_widget("admin/getUserInfoByRetailerCode", {
      retailerCode: retCode,
    })
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            getOnBehalfRetData(retCode);
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              getOnBehalfRetData(retCode);
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              getOnBehalfRetData(retCode);
            },
          });
        }
      });
  };

  const getLedgerBalance = (retcode) => {
    let stateData = JSON.parse(JSON.stringify(ledger));
    stateData.loader = true;
    setLedger(stateData);
    getUserLedgerBalanceDetails({ retailerCode: retcode })
      .then((res) => {
        let previousdata = JSON.parse(JSON.stringify(ledger));
        previousdata.loader = false;
        previousdata.data = res.data.data;
        setLedger(previousdata);
      })
      .catch((error) => {
        let data = JSON.parse(JSON.stringify(ledger));
        data.loader = false;
        setLedger(data);
        setErrorHandler({
          show: true,
          screen: "account_screen",
          errorList: [{ error: error, code: "" }],
          fn: function () {
            getLedgerBalance(retcode);
          },
        });
      });
  };

  const ledgerSectionClick = () => {
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "ledger_section_click",
    });
    navigate(`/account/ledger`);
  };

  const storeSectionClick = (cat) => {
    if (cat.iconName === "yourBounces") {
      navigate(`/products/yourBounces`);
    } else if (
      cat.iconName === "applyCredit" ||
      cat.iconName === "downloadCsv" ||
      cat.iconName === "yourExpiry"
    ) {
      navigate(`/${cat.iconName}`);
    } else if (cat.iconName === "qna") {
      navigate("/help-support/QandA");
    } else if (cat.iconName === "reportIssue") {
      navigate("/help-support/ReportIssue");
    } else if (cat.iconName === "yourOrders") {
      navigate("/account/yourOrders?type=live");
    }else if(cat.iconName==='agreement'){
      navigate("/account/agreements");
    } else {
      navigate(`/account/${cat.iconName}`, {
        state: {
          data: { pageId: cat.sectionName },
        },
      });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("currentScreen", "account_screen");
    localStorage.removeItem("currentScreenBannerData");

    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    if (data) {
      let onBehalfRetCode = localStorage.getItem("onBehalfRetCode");

      if (
        onBehalfRetCode &&
        onBehalfRetCode.length > 0 &&
        onBehalfRetCode !== "undefined" &&
        (data?.admin || data?.salesPerson)
      ) {
        getOnBehalfRetData(onBehalfRetCode);
      } else {
        setUserData(data);
      }

    } else {
      navigate("/login");
    }
  }, []);

  useEffect(()=>{
    if(userData){
      fetchAccounts();
      getLedgerBalance(userData.retailerCode);
    }
  },[userData])
  return (
    <div className="store ">
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <div className="header_store bg_primary header_outer_div">
        <Container className="header_sub display_flex space_btw">
          <div className="font_ubuntu font_weight_md user_data_store">
            <p className="color_secondary">{userData?.retailerCode}</p>
            <p className="color_white font_18">{userData?.vshopName}</p>
            <p className="color_primary_lt2">{userData?.mobileNo}</p>
          </div>
          <div>
            <div className="piggy_bank  font_ubunut font_weight_md color_white display_flex space_btw">
              <div>
                <BsPiggyBank className="piggy_img color_primary_lt2" />
                &nbsp;&nbsp;&nbsp;
              </div>
              <div>
                <span>
                  {" "}
                  ₹ {userData?.totalSavings} <br></br>
                  <span className="color_primary_lt2 font_10">
                    My Savings
                  </span>{" "}
                </span>
              </div>
            </div>
            <div>&nbsp;</div>
            <div
              className="wallet_bank cursor_pointer font_ubunut font_weight_md color_white display_flex space_btw"
              onClick={async () => {
                await Common_Event_Hit("DYNAMIC_EVENT", {
                  eventName: "wallet_history_tap",
                });
                navigate("/store/walletHistory");
              }}
            >
              <div>
                <IoWalletOutline className="piggy_img color_primary_lt2" />
                &nbsp;&nbsp;&nbsp;
              </div>
              <div>
                <span>
                  {" "}
                  ₹ {userData?.creditAmount} <br></br>
                  <span className="color_primary_lt2 font_10">
                    Wallet History
                  </span>{" "}
                </span>
              </div>
              <div className="color_secondary ">
                &nbsp;&nbsp;&nbsp; <AiOutlineRight className="margin_10" />
              </div>
            </div>
          </div>
        </Container>
      </div>
      <br></br>

      {/* ledger shimmer  */}
      {ledger?.loader && (
        <div className="ledger_section animateShimmer">
          <div className="display_flex ">
            <div className="ledger_sub_div">
              <p className="font_10 color_primary_light">CREDIT LEFT</p>
              <p className=" "></p>
            </div>
            <div className="ledger_sub_div">
              <p className="font_10 color_primary_light">YOUR LIMIT</p>
              <p className=" "></p>
            </div>
          </div>
          <div className="display_flex">
            <div className="ledger_sub_div2">
              <p className="font_10 color_primary_light">UNSETTLED</p>
              <p className=" "></p>
              <p
                className="font_10 color_primary_light  "
                style={{ top: "15px", position: "relative" }}
              >
                &#x2a;
              </p>
            </div>
            <div className="ledger_sub_div2">
              <h4 className=" ">
                <span
                  className="font_14"
                  style={{ top: "-7px", position: "relative" }}
                >
                  &nbsp;
                </span>
              </h4>
              <p className="font_12" style={{ margin: "0px" }}>
                Payment Pending
              </p>
            </div>
            <div className="">
              <IoIosArrowForward className="ledger_arrow_click" />
            </div>
          </div>
        </div>
      )}

      {ledger?.data?.isEnabled && !ledger?.loader && (
        <div className="ledger_section" onClick={ledgerSectionClick}>
          {ledger?.data?.ledgerView.isCreditCustomer && (
            <div className="display_flex">
              <div className="ledger_sub_div">
                <p className="font_10 color_primary_light">CREDIT LEFT</p>
                <b>
                  ₹{" "}
                  {ledger?.data?.ledgerView.sanctionedLimit -
                    ledger?.data?.ledgerView.usedLimit}
                </b>
              </div>
              <div className="ledger_sub_div">
                <p className="font_10 color_primary_light">YOUR LIMIT</p>
                <b>₹ {ledger?.data?.ledgerView.sanctionedLimit}</b>
              </div>
            </div>
          )}

          <div className="display_flex">
            {/* <div className="ledger_sub_div2">
              <p className="font_10 color_primary_light">UNSETTLED</p>
              <b>₹ 100.00</b>
              <p
                className="font_10 color_primary_light"
                style={{ top: "15px", position: "relative" }}
              >
                &#x2a; Subject to clearence of cheques
              </p>
            </div> */}
            <div className="ledger_sub_div2">
              <h4>
                <span
                  className="font_14"
                  style={{ top: "-7px", position: "relative" }}
                >
                  ₹ &nbsp;
                </span>
                <b>{Math.abs(ledger?.data?.ledgerView.balanceAmount)}</b> &#x2a;{" "}
              </h4>
              <p className="font_12" style={{ margin: "0px" }}>
                {ledger?.data?.ledgerView.balanceAmount >= 0
                  ? "Overdue Amount"
                  : " Payment Pending"}
              </p>
            </div>
            {ledger?.data?.ledgerView.isCreditCustomer && (
              <div className="">
                <IoIosArrowForward className="ledger_arrow_click" />
              </div>
            )}
          </div>
          {!ledger?.data?.ledgerView.isCreditCustomer && (
            <div className="">
              <IoIosArrowForward className="ledger_arrow_click" />
            </div>
          )}
        </div>
      )}

      <div>
        <Container className="store_account_list">
          {!homeShimmer &&
            categoryRes &&
            categoryRes.map((cat) => {
              if (cat.widgetName === "account_screen") {
                return (
                  <>
                    {webEnabledWidgets.includes(cat.iconName) && (
                      <div onClick={() => storeSectionClick(cat)}>
                        <div className="account_item bg_white store_account_item display_flex space_btw">
                          {cat.iconName === "yourOrders" &&
                            userData?.onlinePaymentEnabled && (
                              <div className="target_dot_store"></div>
                            )}
                          <div>
                            <img
                              className="store_account_icon"
                              src={MY_ACCOUNT_IMAGES[cat.iconName]}
                              alt={cat.iconName}
                            />{" "}
                            <span>&nbsp;&nbsp;{cat.sectionName}</span>
                          </div>
                          <div className="display_flex store-new-tag-container">
                            {cat.newSection && (
                              <>
                                <span
                                  className="new_tag font_ubuntu"
                                  style={{ height: "fit-content" }}
                                >
                                  NEW
                                </span>
                              </>
                            )}
                            <AiOutlineRight className="margin_10 color_secondary" />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                );
              } else if (cat.widgetName === "link_widget") {
                return (
                  <>
                    <div className="your_store_link">
                      <div className="display_flex color_primary  font_weight_md">
                        <img
                          src={Generic}
                          className="store_link_icon"
                          alt="storeIcon"
                        />
                        &nbsp;&nbsp; <span className="font_16">Your Store</span>
                      </div>
                      <span className="color_grey2 font_12 font_weight_lt">
                        {cat.data.shareLink}
                      </span>

                      <div className="display_flex space_around">
                        <a
                          href={cat.data.viewLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button
                            variant="outline-primary"
                            className="edit_btn_return font_ubuntu font_14 link_view_btn"
                          >
                            {/* <BsFillEyeFill/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  View */}
                            View
                          </Button>
                        </a>
                        <Button
                          className="bg_secondary link_view_btn font_14 color_white brdr_none font_ubuntu"
                          onClick={() => copyText(cat.data.shareLink)}
                        >
                          COPY LINK
                        </Button>
                      </div>
                    </div>
                  </>
                );
              }
            })}

            {
              !homeShimmer && !categoryRes && 
              <div onClick={() => storeSectionClick({iconName:'profile'})}>
                        <div className="account_item bg_white store_account_item display_flex space_btw">
                         
                          <div>
                            <img
                              className="store_account_icon"
                              src={MY_ACCOUNT_IMAGES['profile']}
                              alt={'profile'}
                            />{" "}
                            <span>&nbsp;&nbsp;Profile</span>
                          </div>
                          <div className="display_flex store-new-tag-container">
                           
                            <AiOutlineRight className="margin_10 color_secondary" />
                          </div>
                        </div>
                      </div>
            }
          
           
        </Container>
      </div>
      {homeShimmer && (
        <>
          <Container>
            <div className="skeletal_div_sm animateShimmer"></div>
          </Container>
          <Container>
            <div className="skeletal_div_sm animateShimmer"></div>
          </Container>
          <Container>
            <div className="skeletal_div_sm animateShimmer"></div>
          </Container>
          <Container>
            <div className="skeletal_div_sm animateShimmer"></div>
          </Container>
        </>
      )}
    </div>
  );
};

export default Store;
